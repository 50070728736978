<template>
     <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br>{{desc2}}<br>{{desc3}} ----> <a href="http://pf.kakao.com/_AxcWvxj" target="_blank"><u>쿠패스 전용 채널톡 바로가기</u></a></p>
                        <!-- <p class="mb-0">{{desc1}}<br>{{desc2}}&nbsp;&nbsp;&nbsp;&nbsp;<u v-b-modal.modal-3 variant="link" style="color:blue;">{{desc3}}</u></p>
                        <b-modal id="modal-3" size="xl" title="1688 주문서 업로드 방법" ok-title="Save Changes" cancel-title="Close">
                            <p>생성한 주문서를 <a href="https://www.1688.com" target="_blank"><u>1688</u></a>에 접속해서 아래의 순서로 업로드해주세요</p>
                            <img  style="max-width:100%;" :src="require('../../../assets/images/small/1688주문서업로드.png')">
                        </b-modal> -->
                    </div>
                </div>
                <!-- <div class="d-flex float-right"><button type="button" class="btn btn-primary mr-2" v-b-modal.modal-2>견적서</button></div> -->

                        <b-modal id="modal-2" size="xl" title="견적서" ok-only>
                            <div class="text-center">
                                <h3 class="mb-2">적용 회원등급 : {{$store.state.user.grade.toUpperCase()}}</h3>
                                <img style="max-width:100%;" :src="require('../../../assets/images/small/견적서.jpg')">
                            </div>
                        </b-modal>

                <tab-nav :tabs="true" id="myTab-1">
                    <tab-nav-items :active="true" id="req-tab" ariaControls="req" role="tab" :ariaSelected="true" title="구매대행요청" />
                    <tab-nav-items :active="false" id="list-tab" ariaControls="list" role="tab" :ariaSelected="false" title="구매대행요청내역" />
                </tab-nav>

                <tab-content id="myTabContent">
                    <tab-content-item :active="true" id="req" aria-labelled-by="req-tab">

                            <div class="row" v-if="showlist">
                                <div class="col-12">
                                    <div class="table-responsive">
                                        <table id="datatable" class="table data-table table-striped dataTable">
                                            <thead>
                                                <tr class="ligth ligth-data">
                                                    <th>
                                                        <div class="checkbox d-inline-block">
                                                            <input type="checkbox" class="checkbox-input" id="checkbox1" v-model="allchecked" @change="allchecklist(allchecked)">
                                                            <label for="checkbox1" class="mb-0"></label>
                                                        </div>
                                                    </th>
                                                    <th style="min-width:200px;">제품정보</th>
                                                    <th>상품매칭</th>
                                                    <th style="max-width:300px;overflow:hidden;text-overflow: ellipsis;">구매링크</th>
                                                    <th>옵션1_중국어</th>
                                                    <th>옵션2_중국어</th>
                                                    <!-- <th>구매가격</th> -->
                                                    <th>구매재고</th>
                                                    <th>요청사항</th>
                                                    <th>구매요청수량</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(list,index) in lists" :key="index">
                                                    <td>
                                                        <div class="checkbox d-inline-block">
                                                            <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="list.checked">
                                                            <label for="checkbox2" class="mb-0"></label>
                                                        </div>
                                                    </td>
                                                    <!-- <td>{{list.SKUID}}</td> -->

                                                    <td style="min-width:200px;">
                                                        <div class="d-flex align-items-center">
                                                            <!-- <div class="d-flex align-items-center" @click="clickProductName(list)"> -->
                                                            <img :src="list.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image" v-if="list.등록이미지">
                                                            <div v-if="list.상품명">
                                                                {{list.상품명}}
                                                                <p class="mb-0"><small>{{list.바코드}}</small></p>
                                                            </div>
                                                            <div v-else>
                                                                <input type="text" class="form-control" placeholder="상품명입력" v-model="list.상품명init" @change="addProductName(list)">
                                                                <p class="mb-0"><small>{{list.바코드}}</small></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><div :class="matchclass(list.매칭상태)">{{list.매칭상태}}</div></td>
                                                    <td style="max-width:300px;overflow:hidden;text-overflow: ellipsis;">
                                                        <div v-if="list.구매링크 !== undefined">
                                                            <a :href="list.구매링크" target="_blank">{{list.구매링크}}</a>
                                                            <div v-if="list.showspinner">
                                                                <img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <input type="text" class="form-control" placeholder="구매링크입력" v-model="list.구매링크init" @change="matchoption1(index,list.구매링크init)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div v-if="list.옵션이미지 !== undefined">
                                                            <img :src="list.옵션이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                        </div>
                                                        <div v-if="list.옵션1_중국어 !== undefined">
                                                            {{list.옵션1_중국어}}
                                                        </div>
                                                        <div v-else>
                                                            <b-dropdown class="my-class" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                                                <template #button-content>
                                                                &#x1f50d;<span class="sr-only">Search</span>
                                                                </template>
                                                                <b-dropdown-item href="#" v-for="a,i in list.상품매칭.옵션1_중국어" :key="i" @click="option1matching(a,list)">
                                                                    <div class="d-flex align-items-center">
                                                                        <img v-if="a.imageUrl.length > 0" :src="a.imageUrl" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                        <div>
                                                                            {{a.name}}
                                                                        </div>
                                                                    </div>
                                                                </b-dropdown-item>
                                                            </b-dropdown>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div v-if="list.옵션2_중국어 !== undefined">
                                                            {{list.옵션2_중국어}}
                                                        </div>
                                                        <div v-else>
                                                            <b-dropdown class="my-class" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                                                <template #button-content>
                                                                &#x1f50d;<span class="sr-only">Search</span>
                                                                </template>
                                                                <b-dropdown-item href="#" v-for="a,i in list.상품매칭.옵션2_중국어" :key="i" @click="option2matching(a,list)">
                                                                    <div class="d-flex align-items-center">
                                                                        <img v-if="a.imageUrl.length > 0" :src="a.imageUrl" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                        <div>
                                                                            {{a.name}}
                                                                        </div>
                                                                    </div>
                                                                </b-dropdown-item>
                                                            </b-dropdown>
                                                        </div>
                                                    </td>
                                                    <!-- <td>
                                                        {{list.구매가격}}
                                                        <small>({{list.이전구매가격}})</small>
                                                    </td> -->
                                                    <td>{{list.구매재고}}</td>
                                                    <td>
                                                        <div :class="reqclass(list.요청사항)" @click="regrequest(list,index)">{{list.요청사항}}</div>
                                                        <b-modal :id="'modalreq' + index" size="sm" title="구매요청사항" ok-title="요청저장" cancel-title="취소" @ok="savereq($event,list,index)">
                                                            <div class="row">
                                                                <div class="col-md-12 mt-2 d-flex align-items-center justify-contents-start">
                                                                    <h6>1. 구매요청수량</h6>
                                                                    <div class="custom-control-inline ml-3">
                                                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="list.구매요청수량" style="text-align:center;">
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 d-flex align-items-center justify-contents-start">
                                                                    <h6>2. 판매구성수량</h6>
                                                                    <div class="custom-control-inline ml-3">
                                                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="list.판매구성수량" style="text-align:center;" v-b-tooltip.left="'해당 SKU가 2p 구성일 경우에는 2라고 작성해 주세요'">
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 mb-1 d-flex align-items-center justify-contents-start">
                                                                    <h6>3. A-B번들 묶음구성여부</h6>
                                                                    <div class="custom-control ml-3 custom-switch custom-control-inline d-flex align-items-center">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch111" v-model="list.묶음구성여부" @change="confirmBarcode(list)">
                                                                        <label class="custom-control-label" for="customSwitch111">&nbsp;</label>
                                                                        <input v-if="list.묶음구성여부" type="number" :min="0" class="form-control ml-3" placeholder="종류갯수" aria-label="" v-model="list.묶음구성수량" style="text-align:center;" v-b-tooltip.bottom="'3종의 제품을 묶음 구성하는 경우 3으로 작성'">
                                                                        <!-- <input v-if="list.묶음구성여부" type="text" class="form-control ml-3" placeholder="묶음대상바코드번호" aria-label="" v-model="list.바코드" style="text-align:center;" v-b-tooltip.bottom="'묶음구성할 대상의 바코드를 입력해 주세요.'"> -->
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                                    <h6>4. 도착지</h6>
                                                                    <div class="custom-control custom-radio custom-control-inline ml-3">
                                                                       <input type="radio" id="customRadio3" name="customRadio-3" class="custom-control-input" v-model="list.요청내역.destination" value="coupang">
                                                                       <label class="custom-control-label" for="customRadio3">쿠팡센터</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                       <input type="radio" id="customRadio4" name="customRadio-3" class="custom-control-input" v-model="list.요청내역.destination" value="customer">
                                                                       <label class="custom-control-label" for="customRadio4">화주</label>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                                    <h6>5. 출고형태</h6>
                                                                    <div class="custom-control custom-radio custom-control-inline ml-3">
                                                                       <input type="radio" id="customRadio2" name="customRadio-44" class="custom-control-input" v-model="list.요청내역.exportType" value="box">
                                                                       <label v-if="!list.요청내역.destination || list.요청내역.destination == 'coupang'" class="custom-control-label" for="customRadio2">밀크런 택배</label>
                                                                       <label v-if="list.요청내역.destination == 'customer'" class="custom-control-label" for="customRadio2">택배</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                       <input type="radio" id="customRadio1" name="customRadio-44" class="custom-control-input" v-model="list.요청내역.exportType" value="pallet">
                                                                       <label v-if="!list.요청내역.destination || list.요청내역.destination == 'coupang'" class="custom-control-label" for="customRadio1">밀크런 파렛트 (￦35,000/pallet)</label>
                                                                       <label v-if="list.요청내역.destination == 'customer'" class="custom-control-label" for="customRadio1">파렛트 (￦20,000/pallet)</label>
                                                                    </div>
                                                                </div>



                                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                                    <h6>6. 통관정보</h6>
                                                                    <div class="custom-control-inline ml-3">
                                                                        <input type="text" class="form-control" placeholder="신고영문명" aria-label="" v-model="list.신고영문명" style="text-align:center;" v-b-tooltip.bottom="'제품의 신고영문명을 간단히 작성해주세요. ex) 신고영문명 : shoes'">
                                                                    </div>
                                                                    <div class="custom-control-inline">
                                                                        <input type="text" class="form-control" placeholder="HS-CODE" aria-label="" v-model="list.HSCODE" style="text-align:center;" v-b-tooltip.bottom="'통관할 제품의 HS-CODE를 작성해주세요 ex) HS-CODE : 6401.10-1000'">
                                                                    </div>
                                                                    <a href="https://www.bandtrass.or.kr/hsnavi.do?page=F&site=" target="_blank"><u>HS-CODE 검색</u></a>
                                                                </div>


                                                               <div class="col-md-12 mt-3">
                                                                   <h6>7. 검수 및 포장</h6>
                                                               </div>
                                                               <div class="col-md-12">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch6" v-model="list.요청내역.default">
                                                                       <label class="custom-control-label" for="customSwitch6">기본검수/분류/포장/포장자재 (￦200)</label>
                                                                   </div>
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="list.요청내역.barcode" @change="changeBarcode(list)">
                                                                       <label class="custom-control-label" for="customSwitch2" v-b-tooltip.bottom="'바코드라벨이 기본적으로 되어 있거나, 불필요한 경우 OFF해주세요.'">바코드라벨 (Made in China,한글표시사항 기본포함) (￦100)</label>
                                                                   </div>
                                                                   <div class="custom-control custom-switch custom-control-inline" v-if="!list.요청내역.barcode">
                                                                       <input type="checkbox" class="custom-control-input" id="customSwitch212" v-model="list.요청내역.sticker">
                                                                       <label class="custom-control-label" for="customSwitch212" v-b-tooltip.bottom="'바코드라벨 미부착 시 반드시 원산지가 표시되어 있어야 합니다. 불필요한 경우 OFF해주세요.'">원산지스티커 (￦100)</label>
                                                                   </div>
                                                                   <!-- <div class="custom-control custom-switch custom-control-inline" v-if="list.요청내역.barcode">
                                                                       <input type="text" class="form-control" placeholder="바코드입력" aria-label="" v-model="list.바코드" style="text-align:center;" >
                                                                   </div> -->
                                                               </div>

                                                               <div class="col-md-12 mt-0" v-if="list.요청내역.default">
                                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'포장 방법을 쿠패스에 위임해서 진행함. (적합하지 않는 경우 추후 사입 시에는 얼마든지 변경가능하며, 판단 미스에 대한 별도의 보상은 없음.)'">
                                                                       <input type="radio" id="customRadio88" name="customRadio-4" class="custom-control-input" value="auto" v-model="packageMethod" @change="changePackage(list)">
                                                                       <label class="custom-control-label" for="customRadio88">포장 방법 위임</label>
                                                                    </div>
                                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품의 원상태 포장상태 그대로 진행'">
                                                                        <input type="radio" id="customRadio5" name="customRadio-4" class="custom-control-input" value="noPackage" v-model="packageMethod" @change="changePackage(list)">
                                                                        <label class="custom-control-label" for="customRadio5">재포장 생략</label>
                                                                    </div>
                                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품상태가 보이는 투명 OPP 봉투로 재포장 진행'">
                                                                        <input type="radio" id="customRadio6" name="customRadio-4" class="custom-control-input" value="opp" v-model="packageMethod" @change="changePackage(list)">
                                                                        <label class="custom-control-label" for="customRadio6">투명 OPP 봉투 포장 (무료)</label>
                                                                    </div>
                                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품상태가 보이지 않는 불투명 LDPE 봉투로 재포장 진행'">
                                                                        <input type="radio" id="customRadio77" name="customRadio-4" class="custom-control-input" value="LDPE" v-model="packageMethod" @change="changePackage(list)">
                                                                        <label class="custom-control-label" for="customRadio77">불투명 LDPE 봉투 포장 (무료)</label>
                                                                    </div>

                                                                </div>
                                                                <div class="col-md-12 mt-0">
                                                                   <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'박스 겉면에 있는 중국어를 가리는 작업 진행'">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91" v-model="list.요청내역.masking">
                                                                        <label class="custom-control-label" for="customSwitch91">중국어 마스킹 (무료)</label>
                                                                    </div>
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch9" v-model="list.요청내역.airpacking">
                                                                        <label class="custom-control-label" for="customSwitch9">에어캡 포장 (실비)</label>
                                                                    </div>
                                                               </div>

                                                               <div class="col-md-12 mt-0">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91a" v-model="list.요청내역.sewing">
                                                                        <label class="custom-control-label" for="customSwitch91a">원산지라벨 봉제 (6000원/시간)</label>
                                                                        <div class="warning2 ml-2" style="font-weight: 500">* 의류 수입 시 원산지 라벨은 필수 사항입니다.</div>
                                                                    </div>
                                                               </div>
                                                               <div class="col-md-12 mt-0">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91b" v-model="list.요청내역.stamp">
                                                                        <label class="custom-control-label" for="customSwitch91b">원산지 도장 (6000원/시간)</label>
                                                                        <div class="warning2 ml-2" style="font-weight: 500">* 신발류 수입 시 제품에 원산지 라벨봉제 또는 원산지 도장은 필수 사항입니다.</div>
                                                                    </div>
                                                               </div>
                                                               <div class="col-md-12 mt-0">
                                                                   <div class="custom-control custom-switch custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91c" v-model="list.요청내역.tag">
                                                                        <label class="custom-control-label" for="customSwitch91c">원산지 택(태그) (6000원/시간)</label>
                                                                        <div class="warning2 ml-2" style="font-weight: 500">* 가방류 수입 시 원산지 라벨봉제 또는 원산지 택 표기가 필수 사항입니다.</div>
                                                                    </div>
                                                               </div>

                                                                <div class="col-md-12 mt-3 d-flex align-items-center justify-contents-start">
                                                                    <h6>8. 한글표시사항</h6>
                                                                </div>
                                                                <div class="col-md-12 mb-1" v-if="!list.요청내역.barcode">
                                                                    <div class="custom-control-inline w-50 d-flex align-items-center mb-2 d-flex flex-column border">
                                                                        <div class="warning2" style="font-weight: 500;font-size:x-large">※ 바코드라벨을 부착하지 않는 경우,</div>
                                                                        <div class="warning2" style="font-weight: 500;font-size:x-large" v-if="list.요청내역.destination == 'coupang'">쿠팡센터 직납 시 사전에 제품패키지 겉에 반드시 바코드가 부착되거나 새겨져 있어야 합니다.</div>
                                                                        <div class="warning2" style="font-weight: 500;font-size:x-large" v-if="list.요청내역.destination == 'customer'">반드시 원산지표시가 되어 있어야 합니다.</div>
                                                                       <!-- <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="list.바코드" style="text-align:center;"  v-b-tooltip.right="'ex) S00012341234'"> -->
                                                                   </div>
                                                                </div>
                                                                <div class="col-md-12 mb-1" v-if="list.요청내역.barcode">
                                                                    <div class="custom-control-inline w-50 d-flex align-items-center mb-2">
                                                                       <label class="w-50 ml-0">바코드번호</label>
                                                                       <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="list.바코드" style="text-align:center;"  v-b-tooltip.right="'ex) S00012341234'">
                                                                   </div>
                                                                    <div class="name-input">
                                                                       <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                           <label class="w-50 ml-0">제품명</label>
                                                                           <input type="text" class="form-control" placeholder="제품명" aria-label="" v-model="list.상품명" style="text-align:center;"  v-b-tooltip.right="'ex) 쿠플러스 예쁜접시, 10인치'">
                                                                       </div>
                                                                       <div class="warning2" style="font-weight: 500">* 쿠팡에 등록한 상품명 + 옵션명을 같이 입력해주세요</div>
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">수입원/판매원</label>
                                                                       <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="list.한글표시사항['수입원/판매원']" style="text-align:center;"  v-b-tooltip.right="'ex) 회사명'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">제조원</label>
                                                                       <input type="text" class="form-control" placeholder="제조원" aria-label="" v-model="list.한글표시사항.제조원" style="text-align:center;"  v-b-tooltip.right="'ex) 회사명OEM'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">제조국</label>
                                                                       <input type="text" class="form-control" placeholder="제조국" aria-label="" v-model="list.한글표시사항.제조국" style="text-align:center;"  v-b-tooltip.right="'ex) 중국'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">내용량</label>
                                                                       <input type="text" class="form-control" placeholder="내용량" aria-label="" v-model="list.한글표시사항.내용량" style="text-align:center;"  v-b-tooltip.right="'ex) 300ml'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">원료명및성분명</label>
                                                                       <input type="text" class="form-control" placeholder="원료명및성분명(재질)" aria-label="" v-model="list.한글표시사항['원료명및성분명(재질)']" style="text-align:center;"  v-b-tooltip.right="'ex) 도자기제'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">상품유형</label>
                                                                       <input type="text" class="form-control" placeholder="상품유형" aria-label="" v-model="list.한글표시사항.상품유형" style="text-align:center;"  v-b-tooltip.right="'ex) 주방용품'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">사용시주의사항</label>
                                                                       <input type="text" class="form-control" placeholder="사용시주의사항" aria-label="" v-model="list.한글표시사항.사용시주의사항" style="text-align:center;"  v-b-tooltip.right="'ex) 용도 이외에 사용금지'">
                                                                   </div>
                                                                   <div class="custom-control-inline w-50 d-flex align-items-center">
                                                                       <label class="w-50 ml-0">사용기준</label>
                                                                       <input type="text" class="form-control" placeholder="사용기준" aria-label="" v-model="list.한글표시사항.사용기준" style="text-align:center;"  v-b-tooltip.right="'ex) 14세 이상'">
                                                                   </div>
                                                                </div>

                                                                <div class="col-md-6 mt-3 d-flex flex-column">
                                                                    <h6>9. 기존 판매자 협의 금액</h6>
                                                                    <div class="custom-control-inline mt-2 ml-3 d-flex align-items-center">
                                                                        <label class="w-75 ml-0">협의단가(CNY)</label>
                                                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="list.협의단가" style="text-align:center;">
                                                                    </div>
                                                                    <div class="custom-control-inline ml-3 d-flex align-items-center">
                                                                        <label class="w-75 ml-0">총금액(CNY)<br>(전체수량 구매 시 배송비 포함 전체금액)</label>
                                                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="list.협의총금액" style="text-align:center;">
                                                                    </div>
                                                                    <div class="custom-control-inline ml-3 d-flex align-items-center">
                                                                        <label class="w-75 ml-0">협의1688 ID</label>
                                                                        <input type="text" :min="0" class="form-control" placeholder="" aria-label="" v-model="list.협의ID" style="text-align:center;">
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12" style="display: flex">
                                                                    <h6 class="mt-4 mb-1">[ 기타요청사항 ]</h6>
                                                                    <div class="mt-3" style="margin-left: 8px; position: relative; top: 4px; color: #e83e8c">(예: 1688제품 겉박스 중국어 있는부분 작업 요청 내용, 실사, 사이즈, 무게, 센터 입고요청, 신발도장, 의류봉제 등, OPP/LDPE/뽁뽁이 일반검수/정밀검수, 시간이 걸리는 별도의 작업은 6000/시간의 작업비 발생)</div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="list.요청내역.reqcontent"></textarea>
                                                                </div>

                                                            </div>
                                                        </b-modal>

                                                    </td>
                                                    <td>{{list.구매요청수량}}</td>
                                                    <td>
                                                        <div class="d-flex align-items-center list-action">
                                                            <a class="badge badge-primary mr-2" data-toggle="tooltip" v-b-tooltip.top title="요청복사" href="javascript:void(0)" @click="copyReq(list)">
                                                                <i class="ri-file-copy-line mr-0"></i>
                                                            </a>
                                                            <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="옵션재매칭" href="javascript:void(0)" @click="rematching(list,index)">
                                                                <i class="ri-pencil-line mr-0"></i>
                                                            </a>
                                                            <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="Reset" href="javascript:void(0)" @click="reset(list)">
                                                                <i class="ri-eye-line mr-0"></i>
                                                            </a>
                                                            <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="deleteitem(index)">
                                                                <i class="ri-delete-bin-line mr-0"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-center">
                                                        <b-button @click="addlists" variant="outline-primary" class="text-center">+</b-button>
                                                    </td>
                                                    <td colspan="2"><input type="text" class="form-control" placeholder="바코드 번호 (없으면 생략)" @keyup.enter="addlists" v-model="추가바코드"></td>
                                                    <td></td>
                                                    <td>

                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-12 mb-4">
                                    <button type="button" class="btn btn-primary mr-2" v-b-modal.modal-scrollable>구매대행요청</button>
                                    <!-- <button type="button" class="btn btn-secondary mr-2" @click="saveproductdb">상품DB에 저장</button>    -->
                                    <button type="button" class="btn btn-success mr-2" @click="savereqlist">현재상태저장</button>
                                    <button type="button" class="btn btn-warning mr-2" v-b-modal.excelupload>대량주문</button>

                                    <b-modal size="sm" id="excelupload" scrollable title="대량 주문 업로드" ok-only>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <!-- <p><b style="color:red">1688을 통해 구매하지 않거나, 별도 재고로 관리하는 사입제품의 경우, 구매링크를 '사입'이라고 표시해 주세요</b></p>
                                                <p>신규상품, 발주상태 등 상품 내역에 변경사항이 있을 경우 서플라이어에 접속한 후 <u v-b-modal.modal-3>크롬확장프로그램</u>으로 SKU 리스트를 먼저 수집해 주세요</p> -->
                                                <p>[업로드 방법]</p>
                                                <p>1. 엑셀업로드 포맷 다운로드<br>
                                                    → <u>엑셀 파일에서 바코드/구매요청수량 업데이트</u> <br>
                                                    → 2. 업데이트된 엑셀 파일 업로드 순으로 진행해 주세요.</p>

                                            </div>
                                            <div class="col-md-12 mt-4">
                                                <div class="form-group">
                                                    <label>1. 엑셀업로드 포맷</label>
                                                    <div>
                                                    <button type="reset" @click="excelDownFunc" class="btn btn-primary mr-2">Download</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label  for="Code">2. 업데이트된 구매요청양식 업로드</label>
                                                    <div class="custom-file">
                                                    <input ref="pdbupload" type="file" @change="uploadfile" class="custom-file-input" id="inputGroupFile02">
                                                    <label class="custom-file-label" for="inputGroupFile02">{{uploadstatus}}</label>
                                                    </div>
                                                    <div class="col-md-12" v-if="showuploadspinner">
                                                        <div>
                                                            <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-modal>




                                    <!-- <div class="custom-control custom-switch custom-control-inline ml-5">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch11" v-model="checkstock">
                                        <label class="custom-control-label" for="customSwitch11" v-b-tooltip.right="'구매할 제품의 재고 및 옵션상태를 확인하면서 등록합니다.'">구매재고확인등록</label>
                                    </div>              -->
                                </div>
                            </div>



                        <!-- <div class="card d-none">
                            <div class="card-header">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio15" name="customRadio-4" class="custom-control-input" v-model="reqtype" value="로켓배송">
                                    <label class="custom-control-label" for="customRadio15">로켓배송</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio16" name="customRadio-4" class="custom-control-input" v-model="reqtype" value="제트배송">
                                    <label class="custom-control-label" for="customRadio16">제트배송</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio17" name="customRadio-4" class="custom-control-input" v-model="reqtype" value="일반LCL">
                                    <label class="custom-control-label" for="customRadio17">일반LCL</label>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">


                                    <div class="col-md-12 mt-2">
                                        <p class="mb-0" @click="confirmexcelDownFunc"><u variant="link" style="color:blue;">구매대행 요청 양식 다운로드</u></p>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <b-button variant="primary" v-b-modal.modal-scrollable>파일 업로드</b-button> -->

                                        <b-modal id="modal-scrollable" ok-title="동의" cancel-title="거부" scrollable title="구매대행 주의사항" @ok="requestpurchase">
                                            <p class="my-4">
                                                [ 로켓그로스 구매 및 로켓직납 신청시 주의 사항 ]<br><br>

                                                1. 상품구매를 위해 구매대행 요청 전 구매금액 위안화x통합배율(ex. 270, 금리에 따라 변동)
                                                금액이 선결제되어야 합니다.
                                                <br><br>

                                                2. 구매대행 진행 시 제품 재고 부족 등의 이유로 판매자가 배송을
                                                진행하지 않거나 지연되는 경우 해당 제품의 금액만큼 환급되며
                                                이 경우 예치금 환급 시 수수료는 제외됩니다.<br><br>

                                                3. 최종 정산된 금액 기준 사용금액 x 10% 부가세를 포함한 금액 입금 후
                                                전자세금계산서 발행요청을 통해 세금계산서 발행이 가능합니다.
                                                <br><br>

                                                4. 구매대행 신청서 작성시 양식의 내용이 정확하지 않아 발생하는
                                                모든 책임은 요청자에 있으며, 제품 분리 / 폐기 비용 등 이로 인한 금전적
                                                책임은 요청자에 청구됩니다.
                                                제품에 필요한 KC인증/식검/화학 등 인증과 관련한 제품임을 확인하지 않고
                                                요청한 경우에도 무인증으로 인해 발생하는 제반 비용은 요청자에 청구됩니다.
                                                <br><br>

                                                5. 구매대행은 온라인 상품에 대한 구매만을 이야기 하기 때문에
                                                해당 제품의 정품/인증 여부 파손 오염 여부 등에 대하여 보장을
                                                하지 않습니다.
                                                <br><br>

                                                6. 신청서 작성 후 선 결제를 진행하지 않고 7일 이상 초과한
                                                경우 신청서는 자동 폐기처리됩니다.<br><br>

                                                7. 신청서의 오기재로 인한 주문 오류는 쿠플러스 및 쿠플러스 제휴 배송대행지에서
                                                책임을 지지 않습니다.<br><br>

                                                8. 신청서에서 작성되는 모든 정보의 경우 세관신고서에 반영되는
                                                정보이기 때문에 사용자가 정확하게 입력해주셔야 합니다.
                                                허위 정보 작성 정보 미 or 오기재로 인해 발생하는 불이익에
                                                대해서는 쿠플러스 및 쿠플러스 제휴 배송대행지에서 책임지지 않습니다.<br><br>

                                                10. 수입이 금지된 제품(마약/위험물품 등)의 제품 구매 및
                                                배송으로 인해 발생하는 불이익에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서 어떠한
                                                책임도 지지 않습니다.<br><br>

                                                11. 제품의 운송장번호(트래킹 번호)당 하나의 그룹으로 묶여서
                                                진행됩니다. 추후 배송지시  컨펌 등의 확인 및 배송 요청에
                                                대하여 그룹으로 움직이며 그룹을 해지하고자 하시는 경우는
                                                카카오톡 채널 고객센터를 통해 해지 요청을 해주셔야 합니다.
                                                그룹 해지 요청시 추가비용이 발생하며 분리 난이도에 따라
                                                비용이 발생할 수 있습니다.<br><br>

                                                12. 타오바오/알리바바 등에서 주문한 제품이 고객님의 문제로
                                                판매자와 논쟁이 발생할 경우 쿠플러스 및 쿠플러스 제휴 배송대행지에서 조언 등은
                                                가능하지만 책임을 지고 문제를 해결해 드릴 수는 없습니다.
                                                단, 판매자의 문제이거나 저희의 실수로 인해서 발생한 문제의
                                                경우에는 구매대행 담당자가 책임지고 문제를 해결해 드리도록
                                                하겠습니다.<br><br>

                                                13. 제품의 실사는 제품의 최소 포장 단위를 기준으로 촬영을
                                                해드립니다. 기본 실사 요청 시 제품의 도착 여부만을 확인할
                                                수 있게 제공해드리며 제품의 수량  오염  파손  오배송
                                                등의 사항은 확인되지 않을 수 있습니다. 상세한 확인을
                                                원하시는 경우 제품 도착 확인 시 상세 검수를 요청하시기
                                                바랍니다.<br><br>

                                                14. 신청서 작성 입고 확인 시 요청하는 부가서비스의 경우
                                                동일한 서비스라도 입고 확인 시 요청하는 사항에 대해서
                                                비용이 다르게 책정 될 수 있습니다.
                                                <br><br>

                                                15. 제품의 분리 나눔 배송 요청은 기본 지원 해드리지
                                                않으며 요청을 하시고자 하는 경우 카카오톡 채널 고객센터를 통해 분리
                                                나눔하고자 하는 제품의 이미지와 수량을 명확하게 표기하여
                                                요청해주셔야하며 해당 작업으로 인하여 추가비용이
                                                발생할 수 있습니다.<br><br>

                                                17. 고객님께서 컨펌 이후 제품에 이상이 있어서 전 단계로
                                                변경을 원하시거나 해당 제품에 대한 재검수 반품 등을
                                                원하시는 경우 경우에 따라 작업이 불가능할 수 있으며,
                                                작업이 가능한 경우 이에 따른 추가비용이 발생할 수 있습니다.
                                                <br><br>

                                                18. 제품 배송 지시 시 합포장등에 제한은 없으나 실제 인건비 증가에 따른
                                                추가비용이 발생할 수 있습니다.<br><br>

                                                19. 중국 택배의 특성상 판매자가 보내지 않거나 지연시 발생하는
                                                경우 배송대행지에서 적극적으로 판매자에게 재촉하거나, 확인하는 등의
                                                조치를 취하겠으나, 해당 판매자로 인해 발생하는 피해에 대해 배송대행지에서
                                                대신 보상해 드리기는 어렵습니다.
                                                <br><br>

                                                20. 제품의 크기 및 무게가 과도한 경우 추가 비용이 발생하거나
                                                쿠팡센터에 입고 시 회송되더라도 쿠플러스 및 쿠플러스 제휴
                                                배송대행지에서는 책임을 지지 않습니다.<br><br>

                                                21. 결제 금액 불일치 입금자명이 주문인-수취인과 불일치할
                                                경우 입금 확인이 지연되거나 확인이 되지 않을 수 있습니다.
                                                <br><br>

                                                22. 쿠플러스 배송대행지에 제품 보관시 15일 이상 보관 진행된
                                                경우 보관비용이 발생할 수 있습니다.(10000원/CBM.월)
                                                <br><br>

                                                23. 입금 확인의 경우 실시간이 아닌 오전 오후 일괄 적용 되기
                                                때문에 사용자가 실시간 확인 시 지연이 있을 수 있습니다.
                                                <br><br>

                                                24. 지적 재산권 침해 상품(가품,디즈니,레고 등의 브랜드 상품)의
                                                경우 통관 취하시 해당 제품은 무조건 폐기되시며 기타 제품을
                                                포함한 배송의 경우 지재권 제품 폐기 그 외 제품 분할 작업으로
                                                인하여 세관에서 분할/폐기에 대한 수수료가 발생할 수 있습니다.
                                                해당 비용 발생에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서는 책임을 지지 않습니다.
                                                <br><br>

                                                25. 제품명 오기재 품목 가격 고의 누락-제품 가격 수정 등으로
                                                인해 세관 통관 시 과태료(16만원가량)가 발생할 수 있으며 해당
                                                비용 발생에 대하여 쿠플러스 및 쿠플러스 제휴 배송대행지에서는 책임을 지지 않습니다.<br><br>

                                                26. 국제 배송의 특성상 여러 과정을 거치기 때문에 국내 수령 후 제품의
                                                파손에 대하여 배상은 불가합니다. 해당 제품이 당사 도착 시 도착한
                                                운송장 번호와 별도 요청 시 제품의 실사를 제공 해드리며, 고객님께서 이상이
                                                없다고 판단하여 컨펌이 완료된 제품은 이상이 없는 것으로 간주하여
                                                추후 제품의 파손 오염 등의 사항이 발생하더라도 당사에서는
                                                책임을 지지 않습니다. 제품의 파손이 우려되신다면 제품 도착 후
                                                에어캡 포장 배송 지시, 특수 포장 등의 포장 보완을 요청하시면
                                                최선을 다하여 포장 작업을 진행하며 제품의 부피 무게에 따라
                                                기본 비용 보다 추가로 발생하거나 작업이 거부될 수 있습니다.
                                                <br><br>

                                                27. 제품의 색상이 애매하여 작업자가 실수를 할 수 있거나, 헷갈릴 수 있는 부분에 대한 상세한 제품 특성을
                                                검수요청하지 않은 경우 (예시 살구색 vs 연한핑크색, 네이비 vs 블루 색상등) 제품이 잘못 발송되어 쿠팡센터로 부터 회송을 받은 경우
                                                해당 비용 발생에 대한 보상이 어렵습니다.
                                                <br><br>

                                                28. 제품의 해외 발송 / 통관에 제반 문제가 발생한 경우 쿠플러스 및 쿠플러스 제휴 배송대행지에서
                                                이용하고 있는 포워더사의 보상정책에 따릅니다.
                                                <br><br>

                                                29. 제품 특성 상 파손이나 기타 불량이 배송중 발생하는 경우
                                                에어캡 포장 / 특수 포장 등의 포장 보완을 요청해주셔야 하며, 요청해주신 포장 사항을 만족하지 못해
                                                발생한 불량에 대해서는 제품 구매비용의 50%까지 한도 월100만원 내에서 보상이 가능합니다.
                                                <br><br>

                                                30. 배송대행지 작업 실수로 인해 발생하는 제반비용은 제품에 문제가 없는 경우 제품은 화주께
                                                인계가 되므로 제품비용은 보상범위에 포함되지 않으며, 반품택배비 및 해당 작업비에 한해 보상이 가능합니다.
                                                <br><br>

                                                31. 검수단계에서 별도의 작업없이 육안으로 확인이 어려운 해외판매자 귀책 불량의 경우는
                                                해당 제반 비용 발생에 대한 보상이 어렵습니다.
                                                <br><br>
                                            </p>
                                        </b-modal>
                                    <!--
                                    </div>
                                    <div class="col-md-12 d-none">
                                        <div class="form-group">
                                            <div class="custom-file">
                                            <input ref="pdbupload" type="file" @change="uploadfile" class="custom-file-input" id="inputGroupFile02">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                    </tab-content-item>



                    <tab-content-item :active="false" id="list" aria-labelled-by="list-tab">
                        <card>
                            <div class="p-3">
                                <form  @submit.prevent="submit" >
                                    <div class="row">

                                        <div class="col-md-12">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio7" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="today" @change="changedate()">
                                                <label class="custom-control-label" for="customRadio7">오늘</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio8" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="week" @change="changedate()">
                                                <label class="custom-control-label" for="customRadio8">최근7일</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio9" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="month" @change="changedate()">
                                                <label class="custom-control-label" for="customRadio9">최근1개월</label>
                                            </div>
                                            <!-- <div class="custom-control custom-switch custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checksstock">
                                                <label class="custom-control-label" for="customSwitch2">사입제품 포함 발주서만 보기</label>
                                            </div> -->
                                        </div>
                                        <div class="col-md-12 mt-2">
                                        <b-input-group>
                                                <select class="custom-select col-md-2" style="height:40px" v-model="selectoption">
                                                    <option>요청일</option>
                                                </select>
                                                <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate" class="mb-2"></b-form-datepicker>
                                                <p>~</p>
                                                <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate" class="mb-2"></b-form-datepicker>

                                            </b-input-group>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="상품명" v-model="상품명">
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID">
                                            </div>
                                        </div> -->
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="바코드" v-model="바코드">
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-primary mr-2 mt-1" @click="search">검색</button>
                                    <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                                </form>
                            </div>
                        </card>
                       <div class="col-lg-12" v-if="showreqlist">
                           <div class="p-3">
                               <div class="row">
                                   <b-button variant="outline-primary" class="mb-1 mr-2" @click="showtotal">
                                       전체 <b-badge variant="primary" class="ml-2">{{total}}</b-badge>
                                   </b-button>
                               </div>
                           </div>
                           <div class="status">
                               <div class="status-area">
                                   <div class="status-header">
                                       <div class="status-tit">
                                           구매
                                       </div>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('구매전')">구매전</a>
                                       <span>{{구매전}}</span>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('구매확인')">구매진행</a>
                                       <span>{{구매확인}}</span>
                                   </div>
                                   <!-- <div class="status-txt">
                                       <a @click="filterByStatus('구매확정')">구매확정</a>
                                       <span>{{구매확정}}</span>
                                   </div> -->
                               </div>
                               <div class="status-area">
                                   <div class="status-header">
                                       <div class="status-tit">
                                           발송
                                       </div>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('발송전')">발송전</a>
                                       <span>{{발송전}}</span>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('발송지연')">발송지연</a>
                                       <span>{{발송지연}}</span>
                                   </div>
                               </div>
                               <div class="status-area">
                                   <div class="status-header">
                                       <div class="status-tit">
                                           배송
                                       </div>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('배송중')">배송중</a>
                                       <span>{{배송중}}</span>
                                   </div>
                                   <!-- <div class="status-txt">
                                       <a @click="filterByStatus('배송지연예정')">배송지연예정</a>
                                       <span>{{배송지연예정}}</span>
                                   </div> -->
                                   <div class="status-txt">
                                       <a @click="filterByStatus('배송지연')">배송지연</a>
                                       <span>{{배송지연}}</span>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('배송완료')">배송완료</a>
                                       <span>{{배송완료}}</span>
                                   </div>
                               </div>
                               <div class="status-area">
                                   <div class="status-header">
                                       <div class="status-tit">
                                           입/출고
                                       </div>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('입고지연')">입고지연</a>
                                       <span>{{입고지연}}</span>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('입고완료')">입고완료</a>
                                       <span>{{입고완료}}</span>
                                   </div>
                               </div>
                               <div class="status-area">
                                   <div class="status-header">
                                       <div class="status-tit">
                                           오류
                                       </div>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('입고수량오류')">수량오류</a>
                                       <span>{{입고수량오류}}</span>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('입고상품오류')">상품오류</a>
                                       <span>{{입고상품오류}}</span>
                                   </div>
                                   <!-- <div class="status-txt">
                                       <a @click="filterByStatus('오류입고처리')">오류입고처리</a>
                                       <span>{{오류입고처리}}</span>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('오류반품처리')">오류반품처리</a>
                                       <span>{{오류반품처리}}</span>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('오류폐기처리')">오류폐기처리</a>
                                       <span>{{오류폐기처리}}</span>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('오류입고대기')">오류입고대기</a>
                                       <span>{{오류입고대기}}</span>
                                   </div>
                                   <div class="status-txt">
                                       <a @click="filterByStatus('반품폐기완료')">반품폐기완료</a>
                                       <span>{{반품폐기완료}}</span>
                                   </div> -->
                               </div>
                           </div>
                            <CDataTable :headers="headers" :items="reqlists" v-model="tableSelectedItem" :show-select="true" itemKey="_id">
                                <template v-slot:요청일="{item}">
                                    <div>{{getdate(item.요청일)}}</div>
                                </template>
                                <template v-slot:묶음구성여부="{item}">
                                    {{item.묶음구성여부 ? item.묶음구성수량 + '개' : ""}}<br><small>{{item.묶음구성여부 ? '(' + item.바코드 + ")" : ""}}</small>
                                </template>
                                <template v-slot:상품명="{item}">
                                    <div class="d-flex align-items-center" style="padding: 20px">
                                        <img :src="item.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                        <div>
                                            {{item.상품명}}
                                            <p class="mb-0"><small>{{item.SKUID}}</small></p>
                                            <p class="mb-0"><small>{{item.바코드}}</small></p>
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:구매링크="{item}">
                                    <a :href="item.구매링크" target="_blank"><b-icon icon="link" scale="2" variant="secondary"></b-icon></a>
                                </template>
                                <template v-slot:action="{item}">
                                    <div class="d-flex align-items-center list-action">
                                        <a class="badge badge-primary mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="요청복사" href="javascript:void(0)" @click="copyRequest(item)">
                                            <i class="ri-file-copy-line mr-0"></i>
                                        </a>
                                        <a class="badge bg-success mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="요청수정" href="javascript:void(0)" v-b-modal="modalID(item._id)">
                                            <i class="ri-pencil-line mr-0"></i>
                                        </a>


                                        <b-modal :id="'modal' + item._id" size="sm" title="구매요청사항" ok-title="Save Changes" cancel-title="Close" @ok="saverequest(item)">
                                            <div class="row">
                                                <div class="col-md-12 mt-4 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>1. 구매요청수량</h6>
                                                    <div class="custom-control-inline ml-3">
                                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="item.구매요청수량" style="text-align:center;">
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>2. 판매구성수량</h6>
                                                    <div class="custom-control-inline ml-3">
                                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="item.판매구성수량" style="text-align:center;" v-b-tooltip.left="'해당 SKU가 2p 구성일 경우에는 2라고 작성해 주세요'">
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>3. A-B번들 묶음구성여부</h6>
                                                    <div class="custom-control ml-3 custom-switch custom-control-inline d-flex align-items-center">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch111" v-model="item.묶음구성여부" @change="confirmBarcode(item)">
                                                        <label class="custom-control-label" for="customSwitch111">&nbsp;</label>
                                                        <input v-if="item.묶음구성여부" type="number" :min="0" class="form-control ml-3" placeholder="종류갯수" aria-label="" v-model="item.묶음구성수량" style="text-align:center;" v-b-tooltip.bottom="'3종의 제품을 묶음 구성하는 경우 3으로 작성'">
                                                        <!-- <input v-if="list.묶음구성여부" type="text" class="form-control ml-3" placeholder="묶음대상바코드번호" aria-label="" v-model="list.바코드" style="text-align:center;" v-b-tooltip.bottom="'묶음구성할 대상의 바코드를 입력해 주세요.'"> -->
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>4. 도착지</h6>
                                                    <div class="custom-control custom-radio custom-control-inline ml-3">
                                                        <input type="radio" id="customRadio3" name="customRadio-3" class="custom-control-input" v-model="item.요청내역.destination" value="coupang">
                                                        <label class="custom-control-label" for="customRadio3">쿠팡센터</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio4" name="customRadio-3" class="custom-control-input" v-model="item.요청내역.destination" value="customer">
                                                        <label class="custom-control-label" for="customRadio4">화주</label>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>5. 출고형태</h6>
                                                    <div class="custom-control custom-radio custom-control-inline ml-3">
                                                        <input type="radio" id="customRadio2" name="customRadio-44" class="custom-control-input" v-model="item.요청내역.exportType" value="box">
                                                        <label v-if="!item.요청내역.destination || item.요청내역.destination == 'coupang'" class="custom-control-label" for="customRadio2">밀크런 택배</label>
                                                        <label v-if="item.요청내역.destination == 'customer'" class="custom-control-label" for="customRadio2">택배</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio1" name="customRadio-44" class="custom-control-input" v-model="item.요청내역.exportType" value="pallet">
                                                        <label v-if="!item.요청내역.destination || item.요청내역.destination == 'coupang'" class="custom-control-label" for="customRadio1">밀크런 파렛트 (￦35,000/pallet)</label>
                                                        <label v-if="item.요청내역.destination == 'customer'" class="custom-control-label" for="customRadio1">파렛트 (￦20,000/pallet)</label>
                                                    </div>
                                                </div>



                                                <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                                    <h6>6. 통관정보</h6>
                                                    <div class="custom-control-inline ml-3">
                                                        <input type="text" class="form-control" placeholder="신고영문명" aria-label="" v-model="item.신고영문명" style="text-align:center;" v-b-tooltip.bottom="'제품의 신고영문명을 간단히 작성해주세요. ex) 신고영문명 : shoes'">
                                                    </div>
                                                    <div class="custom-control-inline">
                                                        <input type="text" class="form-control" placeholder="HS-CODE" aria-label="" v-model="item.HSCODE" style="text-align:center;" v-b-tooltip.bottom="'통관할 제품의 HS-CODE를 작성해주세요 ex) HS-CODE : 6401.10-1000'">
                                                    </div>
                                                    <a href="https://www.bandtrass.or.kr/hsnavi.do?page=F&site=" target="_blank"><u>HS-CODE 검색</u></a>
                                                </div>


                                                <div class="col-md-12 mt-3">
                                                    <h6>7. 검수 및 포장</h6>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch6" v-model="item.요청내역.default">
                                                        <label class="custom-control-label" for="customSwitch6">기본검수/분류/포장/포장자재 (￦200)</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="item.요청내역.barcode" @change="changeBarcode(item)">
                                                        <label class="custom-control-label" for="customSwitch2" v-b-tooltip.bottom="'바코드라벨이 기본적으로 되어 있거나, 불필요한 경우 OFF해주세요.'">바코드라벨 (Made in China,한글표시사항 기본포함) (￦100)</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline" v-if="!item.요청내역.barcode">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch212" v-model="item.요청내역.sticker">
                                                        <label class="custom-control-label" for="customSwitch212" v-b-tooltip.bottom="'바코드라벨 미부착 시 반드시 원산지가 표시되어 있어야 합니다. 불필요한 경우 OFF해주세요.'">원산지스티커 (￦100)</label>
                                                    </div>
                                                    <!-- <div class="custom-control custom-switch custom-control-inline" v-if="item.요청내역.barcode">
                                                        <input type="text" class="form-control" placeholder="바코드입력" aria-label="" v-model="item.바코드" style="text-align:center;" >
                                                    </div> -->
                                                </div>

                                                <div class="col-md-12 mt-0" v-if="item.요청내역.default">
                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'포장 방법을 쿠패스에 위임해서 진행함. (적합하지 않는 경우 추후 사입 시에는 얼마든지 변경가능하며, 판단 미스에 대한 별도의 보상은 없음.)'">
                                                        <input type="radio" id="customRadio88" name="customRadio-4" class="custom-control-input" value="auto" v-model="packageMethod" @change="changePackage(item)">
                                                        <label class="custom-control-label" for="customRadio88">포장 방법 위임</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품의 원상태 포장상태 그대로 진행'">
                                                        <input type="radio" id="customRadio5" name="customRadio-4" class="custom-control-input" value="noPackage" v-model="packageMethod" @change="changePackage(item)">
                                                        <label class="custom-control-label" for="customRadio5">재포장 생략</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품상태가 보이는 투명 OPP 봉투로 재포장 진행'">
                                                        <input type="radio" id="customRadio6" name="customRadio-4" class="custom-control-input" value="opp" v-model="packageMethod" @change="changePackage(item)">
                                                        <label class="custom-control-label" for="customRadio6">투명 OPP 봉투 포장 (무료)</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품상태가 보이지 않는 불투명 LDPE 봉투로 재포장 진행'">
                                                        <input type="radio" id="customRadio77" name="customRadio-4" class="custom-control-input" value="LDPE" v-model="packageMethod" @change="changePackage(item)">
                                                        <label class="custom-control-label" for="customRadio77">불투명 LDPE 봉투 포장 (무료)</label>
                                                    </div>

                                                </div>
                                                <div class="col-md-12 mt-0">
                                                    <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'박스 겉면에 있는 중국어를 가리는 작업 진행'">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91" v-model="item.요청내역.masking">
                                                        <label class="custom-control-label" for="customSwitch91">중국어 마스킹 (무료)</label>
                                                    </div>
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch9" v-model="item.요청내역.airpacking">
                                                        <label class="custom-control-label" for="customSwitch9">에어캡 포장 (실비)</label>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-0">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91a" v-model="item.요청내역.sewing">
                                                        <label class="custom-control-label" for="customSwitch91a">원산지라벨 봉제 (6000원/시간)</label>
                                                        <div class="warning2 ml-2" style="font-weight: 500">* 의류 수입 시 원산지 라벨은 필수 사항입니다.</div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-0">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91b" v-model="item.요청내역.stamp">
                                                        <label class="custom-control-label" for="customSwitch91b">원산지 도장 (6000원/시간)</label>
                                                        <div class="warning2 ml-2" style="font-weight: 500">* 신발류 수입 시 제품에 원산지 라벨봉제 또는 원산지 도장은 필수 사항입니다.</div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-0">
                                                    <div class="custom-control custom-switch custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitch91c" v-model="item.요청내역.tag">
                                                        <label class="custom-control-label" for="customSwitch91c">원산지 택(태그) (6000원/시간)</label>
                                                        <div class="warning2 ml-2" style="font-weight: 500">* 가방류 수입 시 원산지 라벨봉제 또는 원산지 택 표기가 필수 사항입니다.</div>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-3 d-flex align-items-center justify-contents-start">
                                                    <h6>8. 한글표시사항</h6>
                                                </div>
                                                <div class="col-md-12 mb-1" v-if="!item.요청내역.barcode">
                                                    <div class="custom-control-inline w-50 d-flex align-items-center mb-2 d-flex flex-column border">
                                                        <div class="warning2" style="font-weight: 500;font-size:x-large">※ 바코드라벨을 부착하지 않는 경우,</div>
                                                        <div class="warning2" style="font-weight: 500;font-size:x-large" v-if="item.요청내역.destination == 'coupang'">쿠팡센터 직납 시 사전에 제품패키지 겉에 반드시 바코드가 부착되거나 새겨져 있어야 합니다.</div>
                                                        <div class="warning2" style="font-weight: 500;font-size:x-large" v-if="item.요청내역.destination == 'customer'">반드시 원산지표시가 되어 있어야 합니다.</div>
                                                        <!-- <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="item.바코드" style="text-align:center;"  v-b-tooltip.right="'ex) S00012341234'"> -->
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2 mb-1" v-if="item.요청내역.barcode">
                                                    <div class="custom-control-inline w-50 d-flex align-items-center mb-2">
                                                        <label class="w-50 ml-0">바코드번호</label>
                                                        <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="item.바코드" style="text-align:center;"  v-b-tooltip.right="'ex) S00012341234'">
                                                    </div>
                                                    <div class="name-input">
                                                        <div class="custom-control-inline w-50 d-flex align-items-center">
                                                            <label class="w-50 ml-0">제품명</label>
                                                            <input type="text" class="form-control" placeholder="제품명" aria-label="" v-model="item.상품명" style="text-align:center;"  v-b-tooltip.right="'ex) 쿠플러스 예쁜접시, 10인치'">
                                                        </div>
                                                        <div class="warning2" style="font-weight: 500">* 쿠팡에 등록한 상품명 + 옵션명을 같이 입력해주세요</div>
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">수입원/판매원</label>
                                                        <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="item.한글표시사항['수입원/판매원']" style="text-align:center;"  v-b-tooltip.right="'ex) 회사명'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">제조원</label>
                                                        <input type="text" class="form-control" placeholder="제조원" aria-label="" v-model="item.한글표시사항.제조원" style="text-align:center;"  v-b-tooltip.right="'ex) 회사명OEM'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">제조국</label>
                                                        <input type="text" class="form-control" placeholder="제조국" aria-label="" v-model="item.한글표시사항.제조국" style="text-align:center;"  v-b-tooltip.right="'ex) 중국'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">내용량</label>
                                                        <input type="text" class="form-control" placeholder="내용량" aria-label="" v-model="item.한글표시사항.내용량" style="text-align:center;"  v-b-tooltip.right="'ex) 300ml'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">원료명및성분명</label>
                                                        <input type="text" class="form-control" placeholder="원료명및성분명(재질)" aria-label="" v-model="item.한글표시사항['원료명및성분명(재질)']" style="text-align:center;"  v-b-tooltip.right="'ex) 도자기제'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">상품유형</label>
                                                        <input type="text" class="form-control" placeholder="상품유형" aria-label="" v-model="item.한글표시사항.상품유형" style="text-align:center;"  v-b-tooltip.right="'ex) 주방용품'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">사용시주의사항</label>
                                                        <input type="text" class="form-control" placeholder="사용시주의사항" aria-label="" v-model="item.한글표시사항.사용시주의사항" style="text-align:center;"  v-b-tooltip.right="'ex) 용도 이외에 사용금지'">
                                                    </div>
                                                    <div class="custom-control-inline w-50 d-flex align-items-center">
                                                        <label class="w-50 ml-0">사용기준</label>
                                                        <input type="text" class="form-control" placeholder="사용기준" aria-label="" v-model="item.한글표시사항.사용기준" style="text-align:center;"  v-b-tooltip.right="'ex) 14세 이상'">
                                                    </div>
                                                </div>

                                                <div class="col-md-12" style="display: flex">
                                                    <h6 class="mt-4 mb-1">[ 기타요청사항 ]</h6>
                                                    <div class="mt-3" style="margin-left: 8px; position: relative; top: 4px; color: #e83e8c">(예: 1688제품 겉박스 중국어 있는부분 작업 요청 내용, 실사, 사이즈, 무게, 센터 입고요청, 신발도장, 의류봉제 등, OPP/LDPE/뽁뽁이 일반검수/정밀검수, 시간이 걸리는 별도의 작업은 6000/시간의 작업비 발생)</div>
                                                </div>
                                                <div class="col-md-12">
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="item.요청내역.reqcontent"></textarea>
                                                </div>

                                            </div>
                                        </b-modal>

                                        <a class="badge bg-warning mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="deletelist(item)">
                                            <i class="ri-delete-bin-line mr-0"></i>
                                        </a>
                                    </div>
                                </template>
                            </CDataTable>
                        </div>
                        <div class="col-lg-12 mb-4" v-if="showreqlist">
                            <button type="button" class="btn btn-primary mr-2" @click="download">주문서 다운로드</button>
                        </div>
                    </tab-content-item>
                </tab-content>


            </div>
        </div>
         <b-modal v-model="isNotify" size="sm" title="공지" ok-title="오늘하루 보지 않기" cancel-title="닫기" @ok="todayModal()" @hide="isNotify = !isNotify">
             {{ notifyData?.content }}
         </b-modal>
        <!-- Page end  -->
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import _ from 'lodash'
import { validationMixin } from 'vuelidate'
import { required ,integer	} from 'vuelidate/lib/validators'
import CDataTable from "@/components/common/CDataTable";
// import { VueEditor } from 'vue2-editor'
export default {
    name:'Reqpurchase',
     mixins: [validationMixin],
    validations: {
      purchaseno: { required },
      payment: { required ,integer},
      },
    components:{
    //    VueEditor,
        CDataTable
    },
    data(){
        return{
            headers: [
                {text: '요청일', value: '요청일', align: 'center', width: 100, isSlot: true},
                {text: '요청타입', value: 'reqtype', align: 'center', width: 100, isSlot: false},
                {text: '묶음배송', value: '묶음구성여부', align: 'center', width: 100, isSlot: true},
                {text: '진행현황', value: '진행현황', align: 'center', width: 100, isSlot: false},
                {text: '제품정보', value: '상품명', align: 'left', width: 400, isSlot: true},
                {text: '구매요청수량', value: '구매요청수량', align: 'center', width: 120, isSlot: false},
                {text: '판매구성수량', value: '판매구성수량', align: 'center', width: 120, isSlot: false},
                {text: '구매링크', value: '구매링크', align: 'center', width: 100, isSlot: true},
                {text: '옵션1_중국어', value: '옵션1_중국어', align: 'center', width: 130, isSlot: false},
                {text: '옵션2_중국어', value: '옵션2_중국어', align: 'center', width: 130, isSlot: false},
                {text: 'Action', value: 'action', align: 'center', width: 150, isSlot: true}
            ],
            packageMethod:"auto",
            tableSelectedItem: [],
            obj:{},
            obj1:[],
            obj2:{},
            checkstock : true,

            savedbstatus:false,

            추가바코드:'',
            추가구매수량:'',
            구매링크:'',

            selectdate : 'today',
            startdate: this.getTodaywith(),
            enddate: this.getTodaywith(),
            selectoption:'요청일',
            SKUID: '',
            상품명: '',
            바코드: '',

            total: 0,
            구매전: 0,
            발송전: 0,
            배송중: 0,
            발송지연: 0,
            입고완료: 0,
            일괄삭제: 0,
            구매확인: 0,
            구매확정: 0,
            배송지연: 0,
            //    배송지연예정: 0,
            배송완료: 0,
            입고지연: 0,
            입고수량오류: 0,
            입고상품오류: 0,
            오류입고처리: 0,
            오류반품처리: 0,
            오류폐기처리: 0,
            오류입고대기: 0,
            반품폐기완료: 0,

            showspinner: false,

            allcheckreqlists : false,
            reqlists: [],
            reqlistsinit : [],

            title:"로켓그로스 구매 및 직납요청",
            desc1:"바코드 번호를 입력 후 +버튼(혹은 Enter키)을 눌러 구매대행요청할 상품을 추가해 주세요.",
            desc2:"★ 현재는 1688링크만 구매 가능힙니다.(다른 링크의 경우 쿠패스 채널톡으로 문의해 주세요)",
            desc3:"구매요청 후에는 반드시 쿠패스 채널톡으로 말씀해주세요. 구매현황 및 기타문의는 쿠패스 채널톡을 이용해 주세요",

            showreqlist: false,
            showlist: true,

            checkbarcode: true,
            checktag: false,
            checkstamp: false,
            checksticker: false,

            reqtype: "로켓배송",
            showreqrocket: false,
            showreqjet: false,
            showreqgeneral: false,
            purchaseno:'',
            payment:'',
            reqcontent:'',
            isNotify: false,
            notifyData: null,

            allchecked: false,

            lists: [],
            downloadlists: [],
            currentindex: '',


            uploadstatus:'excel file upload',
            showuploadspinner:false,
            cost : {
                poarrange : 0,
                exportpallet : 0,
                barcode : 0,
                sticker : 0,
                stamp : 0,
                tag : 0,
                default : 0,
            }
        }
    },
    methods: {
        changeBarcode(item){
            if(!item.요청내역.barcode){
                alert('바코드라벨 미부착 시 반드시 원산지가 표시되어 있어야 합니다.')
                item.요청내역.sticker = true;
            } else {
                item.요청내역.sticker = false;
            }
        },
        changePackage(list){
            if(this.packageMethod == 'opp'){
                list.요청내역.opp = true;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'LDPE'){
                list.요청내역.opp = true;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'noPackage'){
                list.요청내역.opp = false;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'auto'){
                list.요청내역.opp = false;
                list.요청내역.LDPE = false;
                list.요청내역.auto = true;
                return
            }
        },
        confirmBarcode(list){
            if(list.바코드.includes('no') || list.바코드.includes('NO') || list.바코드.includes('No') || list.바코드.includes('노')){
                if(list.묶음구성여부){
                    alert('바코드 정보가 없는 경우 묶음구성 진행이 어렵습니다. 바코드를 먼저 생성해 주세요')
                    return
                }
            }
        },
        clickProductName(list){
            if(list.상품명){
                list.상품명 = ''
            }
        },
        todayModal() {
            const today = new Date().toISOString().split('T')[0];
            localStorage.setItem('modalClosedDate', today);
            this.isNotify = false;
        },
        productNameWith(list){
            // if(confirm('변경된 한글표시사항 제품명에 따라 상품명도 변경하시겠습니까?')){
                list.상품명 = list.한글표시사항.제품명;
            // }
        },
        async copyReq(list){
            var newObj = this.deepClone(list);
            if(newObj.상품매칭.옵션1_중국어.length > 0){
                newObj.옵션1_중국어 = undefined;
            } else {
                newObj.옵션1_중국어 = '';
            }
            if(newObj.상품매칭.옵션2_중국어.length > 0){
                newObj.옵션2_중국어 = undefined;
            } else {
                newObj.옵션2_중국어 = '';
            }
            newObj.상품명init = newObj.상품명
            newObj.옵션이미지 = undefined;
            newObj.매칭상태 = '매칭필요';
            if(!newObj.요청내역.destination){
                newObj.요청내역.destination = 'coupang'
            }
            this.lists.push(newObj);
        },
        async copyRequest(list){
            if(list._id){
                delete list._id
            }
            let newObj = this.deepClone(list);
            newObj.진행현황 = '구매전';
            newObj.구매id = '';
            // this.reqinit(newObj);
            let result = await this.confirmreq(newObj);
            result.reqtype = '제트배송';
            if(!result.요청내역.destination){
                result.요청내역.destination = 'coupang'
            }
            this.lists.push(result);
            alert('복사등록완료')
        },
        addProductName(list){
            list.상품명 = list.상품명init;
        },
        getreqlists(){
            this.showlist = false;
            this.lists = [];
            axios.post('/api/reqpurchase/getreqlist',[])
            .then((res) => {
                if (res.data.notifyData) {
                    const lastClosed = localStorage.getItem('modalClosedDate');
                    const today = new Date().toISOString().split('T')[0];

                    if (lastClosed !== today) {
                        this.isNotify = true;
                    }
                    this.notifyData = res.data.notifyData
                }
                if(res.data.lists || res.data.lists == []){
                    res.data.lists.forEach(e => {
                        if(e){
                            e.요청내역.destination = e.요청내역 && e.요청내역.destination ? e.요청내역.destination : 'coupang';
                            this.lists.push(e)
                        }
                    })
                    this.showlist = true;
                } else {
                    alert(res.data.요청결과)
                    this.showlist = true;
                }
            })
            .catch(console.log)
        },
        savereqlist(){
            axios.post('/api/reqpurchase/savereqlist',this.lists)
            .then((res) => {
                alert(res.data.요청결과);
            })
            .catch(console.log)
        },
        labelling(요청내역){
            if(요청내역.barcode){
                요청내역.한글표시사항 = true
            } else {
                요청내역.한글표시사항 = false
            }
        },
        // qadefault(요청내역){
        //     if(!요청내역.default){
        //         alert('기본검수/포장/포장자재가 불필요한 경우 해')
        //     }
        // },
        async savereq($event,list,index){
            $event.preventDefault();
            var foo = await this.confirmlist(list)
            if(!foo){
                return
            }
            list.요청사항 = '등록완료';
            this.$bvModal.hide('modalreq' + index);

        },
        async saverequest(item){
            const index = this.reqlists.findIndex(i => i._id === item._id);
            if(this.reqlists[index].진행현황 === '입고완료'){
                alert('입고가 완료된 상품은 수정이 불가능 합니다.')
                return
            }
            if(this.reqlists[index].진행현황 === '구매전'){
                const res = await this.updatereqlist([item])
                if (res) {
                    this.reqlistsinit[index] = this.deepClone(this.reqlists[index])
                    this.$forceUpdate();
                }
            } else {
                if(this.reqlists[index]['구매요청수량'] !== this.reqlistsinit[index]['구매요청수량']){
                    alert('이미 구매 진행된 상품은 구매요청수량을 변경할 수 없습니다.')
                    return
                } else {
                    const res = await this.updatereqlist([item])
                    if (res) {
                        this.reqlistsinit[index] = this.deepClone(this.reqlists[index])
                        this.$forceUpdate();
                    }
                }
            }
        },
        async updatereqlist(arr) {
            if (arr.length == 1) {
                try {
                    const res = await axios.post('/api/reqpurchase/updatereqpurchase', arr);
                    if (res.data.요청결과 === '변경완료') {
                        alert(res.data.요청결과);
                        return true;
                    } else {
                        alert(res.data.요청결과);
                        console.log(res.data);
                        return false;
                    }
                } catch (error) {
                    console.error(error);
                    return false;
                }
            } else {
                try {
                    const res = await axios.post('/api/reqpurchase/updatereqpurchase', this.reqlists);
                    if (res.data.요청결과 === '변경완료') {
                        alert(res.data.요청결과);
                        return true;
                    } else {
                        alert(res.data.요청결과);
                        console.log(res.data);
                        return false;
                    }
                } catch (error) {
                    console.error(error);
                    return false;
                }
            }
        },
        usergrade(){
            // console.log(this.$store.state.user)
            if(this.$store.state.user.grade == 'general'){
                this.cost.poarrange = 100
                this.cost.exportpallet = 20000
                this.cost.barcode = 500
                this.cost.sticker = 300
                this.cost.stamp = 300
                this.cost.tag = 300
                this.cost.default = 300
                this.cost.store = 5000
            }
            if(this.$store.state.user.grade == 'vip'){
                this.cost.poarrange = 100;
                this.cost.exportpallet = 20000
                this.cost.barcode = 300
                this.cost.sticker = 200
                this.cost.stamp = 200
                this.cost.tag = 300
                this.cost.default = 200
                this.cost.store = 3000
            }
            if(this.$store.state.user.grade == 'vvip'){
                this.cost.poarrange = 100;
                this.cost.exportpallet = 20000
                this.cost.barcode = 300
                this.cost.sticker = 200
                this.cost.stamp = 200
                this.cost.tag = 300
                this.cost.default = 200
                this.cost.store = 0
            }
        },
        async addlists(){
            if(this.추가바코드 == ''){
                this.추가바코드 = "NOBARCODE"
            }
                // return
            // } else {
                this.showlist = false;
                var obj
                // if(this.$store.state.growthdb && !this.$store.state.growthdb.find(e => e.바코드 == this.추가바코드)){
                //     var obj = {
                //         checked : false,
                //         showspinner : false,
                //         // SKUID : '',
                //         바코드 : this.추가바코드,
                //         상품명 : '',
                //         상품명init : '',
                //         구매링크 : undefined,
                //         구매링크init : undefined,
                //         옵션1_중국어 : undefined,
                //         옵션2_중국어 : undefined,
                //         옵션이미지: undefined,
                //         매칭상태 : '매칭필요',
                //         상품매칭 : {
                //             옵션1_중국어 : [],
                //             옵션2_중국어 : [],
                //         },
                //         구매재고 : '',
                //         구매요청수량 : '',
                //         판매구성수량 : 1,
                //         묶음구성여부 : false,
                //         묶음구성수량 : '',
                //         신고영문명 : '',
                //         신고가격 : '',
                //         HSCODE : '',
                //         진행현황 : '구매전',
                //         재고동봉 : true,
                //         등록이미지 : '',
                //         요청사항 : '요청등록',
                //         reqtype : '제트배송',
                // sizeX : ob.sizeX ? ob.sizeX : '',
                //     sizeY : ob.sizeY ? ob.sizeY : '',
                //     sizeZ : ob.sizeZ ? ob.sizeZ : '',
                //     weight : ob.weight ? ob.weight : '',
                //         요청내역 : {
                //             default : true,
                //             poarrange : false,

                //             한글표시사항 : true,
                //             barcode : true,
                //             sticker : false,
                //             stamp : false,
                //             tag : false,
                //             reqcontent : '',
                //             opp : false,
                //             LDPE : false,
                //             airpacking : false,
                //             exportType : 'pallet',
                //         },
                //         한글표시사항 : {
                //             제품명 : '',
                //             '수입원/판매원' : '',
                //             제조원 : '',
                //             제조국 : '',
                //             내용량 : '',
                //             '원료명및성분명(재질)' : '',
                //             상품유형 : '',
                //             사용시주의사항 : '',
                //             사용기준 : '',
                //         },
                //         polists : [],
                //         selectpo : '',
                //     }
                //     this.lists.push(obj)
                // } else {
                //     if(this.$store.state.growthdb && this.$store.state.growthdb.find(e => e.바코드 == this.추가바코드)){
                //         var ob = this.$store.state.growthdb.find(e => e.바코드 == this.추가바코드);
                //         if(ob.요청내역){
                //             var 요청내역 = ob.요청내역
                //         } else {
                //             요청내역 = {
                //                 default : true,
                //                 poarrange : false,

                //                 boxnumber : '',
                //                 한글표시사항 : true,
                //                 barcode : true,
                //                 sticker : false,
                //                 stamp : false,
                //                 tag : false,
                //                 reqcontent : '',
                //                 opp : false,
                //                 LDPE : false,
                //                 airpacking : false,
                //                 exportType : 'pallet',
                //             }
                //         }
                //         obj = {
                //             checked : false,
                //             showspinner : false,
                //             // SKUID : ob['SKU ID'],
                //             바코드 : ob.바코드 ? ob.바코드 : '',
                //             상품명 : ob.상품명,
                //             상품명init : '',
                //             진행현황 : '구매전',
                //             구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
                //             구매링크init : ob.구매링크 ? ob.구매링크 : undefined,
                //             옵션1_중국어 : ob.옵션1_중국어 ? ob.옵션1_중국어 : undefined,
                //             옵션2_중국어 : ob.옵션2_중국어 ? ob.옵션2_중국어 : undefined,
                //             옵션이미지: ob.옵션이미지 ? ob.옵션이미지 : undefined,
                //             매칭상태 : ob.매칭상태 ? ob.매칭상태 : '매칭필요',
                //             상품매칭 : {
                //                 옵션1_중국어 : [],
                //                 옵션2_중국어 : [],
                //             },
                //             구매재고 : '',
                //             구매요청수량 : '',
                //             판매구성수량: ob.판매구성수량 ? ob.판매구성수량 : 1,
                //             묶음구성여부 : ob.묶음구성여부 ? ob.묶음구성여부 : false,
                //             묶음구성수량 : ob.묶음구성수량 ? ob.묶음구성수량 : '',
                //             묶음대상바코드 : ob.묶음대상바코드 ? ob.묶음대상바코드 : '',
                //             신고영문명 : ob.신고영문명 ? ob.신고영문명 : '',
                //             신고가격 : ob['신고가격(USD)'] ? ob['신고가격(USD)'] : '',
                //             HSCODE : ob['HS-CODE'] ? ob['HS-CODE'] : '',
                //             재고동봉 : true,
                //             등록이미지 : ob.등록이미지,
                //             요청사항 : '요청등록',
                //             reqtype : '제트배송',
                //             sizeX : ob.sizeX ? ob.sizeX : '',
                //             sizeY : ob.sizeY ? ob.sizeY : '',
                //             sizeZ : ob.sizeZ ? ob.sizeZ : '',
                //             weight : ob.weight ? ob.weight : '',
                //             요청내역 : 요청내역,
                //             한글표시사항 : {
                //                 제품명 : ob.상품명,
                //                 '수입원/판매원' : ob['수입원/판매원'] ? ob['수입원/판매원'] : '',
                //                 제조원 : ob.제조원 ? ob.제조원 : '',
                //                 제조국 : ob.제조국 ? ob.제조국 : '',
                //                 내용량 : ob.내용량 ? ob.내용량 : '',
                //                 '원료명및성분명(재질)' : ob['원료명및성분명(재질)'] ? ob['원료명및성분명(재질)'] : '',
                //                 상품유형 : ob.상품유형 ? ob.상품유형 : '',
                //                 사용시주의사항 : ob.사용시주의사항 ? ob.사용시주의사항 : '',
                //                 사용기준 : ob.사용기준 ? ob.사용기준 : '',
                //             },
                //             polists : [],
                //             selectpo : '',
                //         }
                //         this.lists.push(obj)
                //     } else {
                        obj = {
                            checked : false,
                            showspinner : false,
                            // SKUID : '',
                            바코드 : this.추가바코드,
                            상품명 : '',
                            상품명init : '',
                            구매링크 : undefined,
                            구매링크init : undefined,
                            옵션1_중국어 : undefined,
                            옵션2_중국어 : undefined,
                            옵션이미지: undefined,
                            매칭상태 : '매칭필요',
                            상품매칭 : {
                                옵션1_중국어 : [],
                                옵션2_중국어 : [],
                            },
                            구매재고 : '',
                            구매요청수량 : '',
                            판매구성수량 : 1,
                            묶음구성여부 : false,
                            묶음구성수량 : '',
                            묶음대상바코드 : '',
                            신고영문명 : '',
                            신고가격 : 0,
                            HSCODE : '',
                            진행현황 : '구매전',
                            재고동봉 : true,
                            등록이미지 : '',
                            요청사항 : '요청등록',
                            reqtype : '제트배송',
                            sizeX : '',
                            sizeY : '',
                            sizeZ : '',
                            weight : '',
                            요청내역 : {
                                default : true,
                                poarrange : false,

                                한글표시사항 : true,
                                barcode : true,
                                sticker : false,
                                sewing : false,
                                stamp : false,
                                tag : false,
                                reqcontent : '',
                                opp : false,
                                LDPE : false,
                                airpacking : false,
                                exportType : 'box',
                                destination : 'coupang',
                                masking : false,
                                auto : true,
                            },
                            한글표시사항 : {
                                제품명 : '',
                                '수입원/판매원' : '',
                                제조원 : '',
                                제조국 : '',
                                내용량 : '',
                                '원료명및성분명(재질)' : '',
                                상품유형 : '',
                                사용시주의사항 : '',
                                사용기준 : '',
                            },
                            polists : [],
                            selectpo : '',
                        }
                        this.lists.push(obj)
                //     }
                // }

            // }
            // this.reqinit(this.lists[this.lists.length-1])
            this.추가바코드 = '';
            this.추가구매수량 = '';
            this.showlist = true;
        },
        polistchange(b,list){
            list.발주수량 = b.sku수량;
            list.구매요청수량 = b.sku수량;
            list.selectpo = b.발주번호;
        },
        reqinit(obj){
            // if(obj.SKUID != ''){
            //     var cloneobj = {};
            //     this.$store.state.polist.forEach(e => {
            //         if(!e.발주상태.includes('거래명세서') && !e.발주상태.includes('발주취소')){
            //             e.발주세부내역.forEach(ele => {
            //                 if(ele.SKUID == obj.SKUID){
            //                     cloneobj = this.deepClone(e);
            //                     cloneobj.sku수량 = parseInt(ele.확정수량);
            //                     obj.polists.push(this.deepClone(cloneobj))
            //                 }
            //             })
            //         }
            //     })
            // }
            if(obj.구매링크 && this.checkstock){
                this.lists[this.lists.length-1].showspinner = true;
                this.currentindex = this.lists.length-1;
                this.sendmessage(obj.구매링크);
            } else {
                this.showlist = true;
            }
        },
        showtotal(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit;
                this.showreqlist = true;
            }, 10);
        },
        showa(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '구매전');
                this.showreqlist = true;
            }, 10);
        },
        showb(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '구매발송전');
                this.showreqlist = true;
            }, 10);
        },
        showc(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '구매발송완료');
                this.showreqlist = true;
            }, 10);
        },
        showd(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '입고완료');
                this.showreqlist = true;
            }, 10);
        },
        download() {
            var arr = [];
            if(_.isEmpty(this.tableSelectedItem)){
                arr = this.reqlists
            } else {
                arr = this.tableSelectedItem
            }
            function getToday(){
                var date = new Date();
                var year = date.getFullYear();
                var month = ("0" + (1 + date.getMonth())).slice(-2);
                var day = ("0" + date.getDate()).slice(-2);

                return year + month + day;
            }
            var obj = {}
            var ar = []
            arr.forEach(e => {
                obj = {
                    요청일 : this.getdate(e.요청일),
                    진행현황 : e.진행현황,
                    등록이미지 : e.등록이미지,
                    상품명 : e.상품명,
                    'SKU ID' : e.SKUID,
                    바코드 : e.바코드,
                    구매요청수량 : e.구매요청수량,
                    구매링크 : e.구매링크,
                    옵션1_중국어 : e.옵션1_중국어,
                    옵션2_중국어 : e.옵션2_중국어,
                }
                ar.push(obj)
            })

            const workBook = XLSX.utils.book_new()
            const workSheet1 = XLSX.utils.json_to_sheet(ar)
            XLSX.utils.book_append_sheet(workBook, workSheet1, '구매대행요청내역')
            XLSX.writeFile(workBook, '구매대행요청내역' + '_' + getToday() + '.xlsx')
        },
        gettimestamp(date){
            return new Date(date).getTime()
        },
        search(){
            this.showreqlist = false;
            var start = this.gettimestamp(this.startdate)
            var end = this.gettimestamp(this.enddate) + (1000*60*60*24) - 1
            axios.post('/api/reqpurchase/getlists',{start:start,end:end,상품명:this.상품명,바코드:this.바코드})
                .then((res) => {
                    this.$store.commit('reqpurchaseupdate',res.data.reqpurchase);
                    console.log(res.data.요청결과)

                    var arr = [];
                    res.data.reqpurchase.forEach(e => {
                        e.진행현황 = e.진행현황 ? e.진행현황 : "구매전";
                        e.checked = false;
                        e.요청내역.destination = e.요청내역.destination ? e.요청내역.destination : "coupang";
                        arr.push(this.deepClone(e))
                    })
                    console.log(arr)
                    this.구매전 = arr.filter(e => e.진행현황 === '구매전').length;
                    this.구매확인 = arr.filter(e => e.진행현황 === '구매확인').length;
                    //    this.구매확정 = arr.filter(e => e.진행현황 === '구매확정').length;
                    this.발송전 = arr.filter(e => e.진행현황 === '발송전').length;
                    this.배송중 = arr.filter(e => e.진행현황 === '발송완료').length;
                    this.발송지연 = arr.filter(e => e.진행현황 === '발송지연').length;
                    this.입고완료 = arr.filter(e => e.진행현황 === '입고완료').length;
                    //    this.배송지연예정 = arr.filter(e => e.진행현황 === '배송지연예정').length;
                    this.배송지연 = arr.filter(e => e.진행현황 === '배송지연').length;
                    this.배송완료 = arr.filter(e => e.진행현황 === '배송완료').length;
                    this.입고지연 = arr.filter(e => e.진행현황 === '입고지연').length;
                    this.입고완료 = arr.filter(e => e.진행현황 === '입고완료').length;
                    this.입고수량오류 = arr.filter(e => e.진행현황 === '입고수량오류').length;
                    this.입고상품오류 = arr.filter(e => e.진행현황 === '입고상품오류').length;
                    this.오류입고처리 = arr.filter(e => e.진행현황 === '오류입고처리').length;
                    this.오류반품처리 = arr.filter(e => e.진행현황 === '오류반품처리').length;
                    this.오류폐기처리 = arr.filter(e => e.진행현황 === '오류폐기처리').length;
                    this.오류입고대기 = arr.filter(e => e.진행현황 === '오류입고대기').length;
                    this.반품폐기완료 = arr.filter(e => e.진행현황 === '반품폐기완료').length;
                    this.total = this.구매전 +
                        this.구매확인 +
                        this.발송전 +
                        this.배송중 +
                        this.발송지연 +
                        this.입고완료 +
                        this.배송지연 +
                        this.배송완료 +
                        this.입고지연 +
                        this.입고완료 +
                        this.입고수량오류 +
                        this.입고상품오류 +
                        this.오류입고처리 +
                        this.오류반품처리 +
                        this.오류폐기처리 +
                        this.오류입고대기 +
                        this.반품폐기완료;

                    this.reqlistsinit = arr;
                    this.reqlists = arr;

                    this.showreqlist = true;
                })
                .catch(e => console.log('err821' + e))
        },
        getdate(timestamp){
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        changedate(){
            var now
            var target
            if(this.selectdate == 'today'){
                this.startdate = this.getTodaywith();
                this.enddate = this.getTodaywith();
            } else {
                if(this.selectdate == 'week'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*7

                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                } else {
                    if(this.selectdate == 'month'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*30
                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                    }
                }
            }
        },
        allcheck(){
            if(this.allcheckreqlists){
                this.reqlists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.reqlists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        regrequest(list,index){
            if(!list.상품명){
                alert('상품명을 먼저 입력해 주세요')
                return
            }
            this.$bvModal.show('modalreq' + index);
        },
        confirmlists(req){
            var arr = [];
            if(this.lists.filter(e => e.checked).length == 0){
                arr = this.lists
                this.lists.forEach(e => {e.checked = true})
            } else {
                arr = this.lists.filter(e => e.checked)
            }
            if(!req){
                if(arr.filter(e => !e.상품명).length > 0){
                    alert('상품명을 확인해 주세요')
                    return []
                }

                if(arr.filter(e => e.구매링크 === undefined).length > 0){
                    alert('구매링크를 모두 입력해 주세요')
                    return []
                }
                if(arr.filter(e => e.옵션1_중국어 === undefined || e.옵션2_중국어 === undefined).length > 0){
                    if(!confirm('매칭이 안된 옵션이 있습니다. 이대로 진행하시겠습니까?')){
                        return []
                    }
                }
                if(arr.filter(e => e.요청사항 !== '등록완료').length > 0){
                    alert('요청사항이 등록되지 않은 항목이 있습니다. \'요청등록\'버튼을 눌러 요청을 등록해 주세요.')
                    return []
                }
            }
            if(arr.filter(e => !e.요청내역.barcode && !e.요청내역.sticker && !e.요청내역.stamp && !e.요청내역.tag).length > 0){
                if(!confirm('원산지 작업내용이 없는 경우 통관이 불가합니다. 상품에 이미 원산지작업이 되어 있습니까?')){
                    return []
                }
            }
            if(arr.filter(e => !e.신고영문명).length > 0){
                alert("요청내역 중 신고영문명을 작성해주세요. ex)여성신발 -> women's shoes ")
                return []
            }
            // if(arr.filter(e => !e.신고가격).length > 0){
            //     alert("요청내역 중 신고가격을 USD로 환산하여 작성해주세요. ex) 2달러 -> 2 ")
            //     return []
            // }
            if(arr.filter(e => !e.HSCODE).length > 0){
                alert("요청내역 중 HS-CODE를 작성해주세요. ex) 6303.92-0000 ")
                return []
            }
            if(arr.filter(e => e.HSCODE.length !== 12).length > 0){
                alert("HS-CODE를 Full name으로 작성해주세요. ex) 6303.92-0000 ")
                return []
            }
            if(arr.filter(e => e.구매요청수량 == 0 || !e.구매요청수량).length > 0){
                alert("구매요청수량이 작성안된 제품이 있습니다.")
                return []
            }
            if(arr.filter(e => parseInt(e.구매재고) <  e.구매요청수량).length > 0){
                if(!confirm("구매요청수량보다 구매할수 있는 재고가 적은 상품이 있습니다. 그래도 구매요청 하시겠습니까?")){
                    return []
                }
            }
            // if(arr.filter(e => this.reqlists.filter(ele => ele.진행현황 == '구매전' && ele.구매링크 == e.구매링크 && (e.옵션1_중국어 && ele.옵션1_중국어 ? e.옵션1_중국어 == ele.옵션1_중국어 : true) && (e.옵션2_중국어 && ele.옵션2_중국어 ? e.옵션2_중국어 == ele.옵션2_중국어 : true))).length > 0){
            //     if(!confirm("동일한 제품이 구매전인 구매대행요청내역이 존재합니다. 동일주문일 경우 해당 요청내역에서 수량등의 정보를 변경하실 수 있습니다. 그래도 추가 요청하시겠습니까?")){
            //         return []
            //     }
            // }
            return arr
        },
        confirmlist(list){
            if(!list.바코드){
                if(!confirm('바코드가 없는 경우 로켓그로스 출고가 불가능합니다.')){
                    return false
                }
            }
            if(!list.신고영문명){
                alert("요청내역 중 신고영문명을 작성해주세요. ex)여성신발 -> women's shoes ")
                return false
            }
            // if(!list.신고가격){
            //     alert("요청내역 중 신고가격을 USD로 환산하여 작성해주세요. ex) 2달러 -> 2 ")
            //     return false
            // }
            if(!list.HSCODE){
                alert("요청내역 중 HS-CODE를 작성해주세요. ex) 6303.92-0000 ")
                return false
            }
            if(list.HSCODE.length !== 12){
                alert("HS-CODE를 Full name으로 작성해주세요. ex) 6303.92-0000 ")
                return false
            }
            if(list.구매요청수량 == 0 || !list.구매요청수량){
                alert("구매요청수량을 작성해 주세요.")
                return false
            }
            if(list.판매구성수량 == 0 || !list.판매구성수량){
                alert("판매구성수량을 작성해 주세요.")
                return false
            }
            list.한글표시사항.제품명 = list.상품명;
            if(list.요청내역.barcode && Object.keys(list.한글표시사항).find(e => e !== '제품명' && !list.한글표시사항[e])){
                alert('한글표시사항을 모두 작성해 주세요')
                return
            }
            return true
        },
        async requestpurchase(){
            var list = await this.confirmlists(false)
            if(list.length == 0){
                alert('요청할 목록이 없습니다.')
                return
            }
            // if(confirm('현재 요청하신 정보를 상품DB에 저장하시겠습니까? (저장 이후에 요청시 구매링크와 옵션이 자동 매핑되며, SKUID가 없는 제품은 저장되지 않습니다.)')){
            //     await this.saveproductdb()
            // }
            var cnt = list.length;
            var arr = [];
            var obj = {};
            var now = Date.now();
            list.forEach(e => {
                obj = this.deepClone(e);
                obj.companyid = this.$store.state.user.companyid;
                obj.요청일 = now;
                obj.구매_id = '';
                arr.push(this.deepClone(obj));
            })
            if(confirm("총 " + cnt + "건을 구매대행 요청하시겠습니까?")){
                axios.post('/api/reqpurchase/requestpurchase',arr)
                .then((res) => {
                    this.$store.commit('reqpurchaseupdate',res.data.reqpurchase);
                    alert(res.data.요청결과)
                    var ar = this.lists.filter(e => !e.checked)
                    this.lists = ar;
                })
                .catch(console.log)
            }
        },
        async saveproductdb(){

            var list = await this.confirmlists(false)
            if(list.length == 0){
                alert('업데이트할 목록이 없습니다.')
                return
            }
            // this.savedbstatus = true;
            var arr = list
            console.log(list)
            axios.post('/api/reqpurchase/updateproductdb',arr)
            .then((res) => {
                this.$store.commit('productdbupdate',res.data.productdb);
                alert(res.data.요청결과)
            })
            .catch(console.log)
        },
        deleteindexfromarray(array,index){
            return array.slice(0,index).concat(array.slice(index+1))
        },
        deleteitem(index){
            this.lists = this.deleteindexfromarray(this.lists,index);
        },
        confirmexcelDownFunc(){
            if(this.reqtype == '로켓배송'){
                this.excelDownFunc()
                return
            }
            if(this.reqtype == '제트배송'){
                this.excelDownFunc()
                return
            }
            if(this.reqtype == '일반LCL'){
                if(confirm('일반LCL에서 SKUID는 10000000 이하의 숫자로 임의로 기입하시면 됩니다.')){
                    this.excelDownFunc()
                    return
                }
            }
        },
        modalreq(index){
            return 'modalreq' + index;
        },
        reset(list){
            list.상품명 = '';
            list.상품명init = '';
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            list.구매링크 = undefined;
            list.구매링크init = '';
        },
        rematching(list,index){
            if(!list.구매링크){
                alert('구매링크를 입력해 주세요')
                return
            }
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            this.matchoption1(index,list.구매링크)
        },
        fileupload(){
            this.$refs.pdbupload.value = '';
            this.$refs.pdbupload.click();
        },
        option1matching(a,list){
            // this.showlist = false;
            if(a.imageUrl){
                // if(!list.등록이미지){
                    list.등록이미지 = a.imageUrl;
                // }
                list.옵션이미지 = a.imageUrl;
            }
            list.옵션1_중국어 = a.name;


            // if(this.checkstock){
            if(list.옵션2_중국어 == ''){
                // var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                // list.구매재고 = parseInt(list.item.productSaleInfo.find(e => e.skuAttributes[0].옵션1_중국어 === list.옵션1_중국어).amountOnSale);
                list.item.productSkuInfos.forEach(e => {
                    if (e.skuAttributes[0].value === list.옵션1_중국어) {
                        list.구매재고 = e.amountOnSale
                    }
                })
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                    list.매칭상태 = '매칭완료';
                }
            } else {
                if(list.옵션2_중국어 !== undefined){
                    // properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                    // var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                    // list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                    // list.구매sku = list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).sku_id;
                    list.item.productSkuInfos.forEach(e => {
                        if (e.skuAttributes[0].value === list.옵션1_중국어 &&
                            e.skuAttributes[1].value === list.옵션2_중국어)
                        {
                            list.구매재고 = e.amountOnSale
                        }
                    })
                    if(list.구매재고 == 0){
                        alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                    } else {
                        alert('구매 가능한 재고수량 : ' + list.구매재고)
                        list.매칭상태 = '매칭완료';
                    }
                } else {
                    list.상품매칭.옵션2_중국어 = []
                    list.item.productSkuInfos.forEach(e => {
                        if (e.skuAttributes[0].value === list.옵션1_중국어) {
                            list.상품매칭.옵션2_중국어.push({
                                name: e.skuAttributes[1].value,
                                imageUrl: e.skuAttributes[1].skuImageUrl ? e.skuAttributes[1].skuImageUrl : '',
                            })
                        }
                    })
                }
            }
            // if(list.등록이미지 == '' && list.옵션이미지 == ''){
            //     list.등록이미지 = list.item.pic_url;
            //     list.옵션이미지 = list.item.pic_url;
            // }
            // this.showlist = true;
            // }
        },
        option2matching(a,list){
            // this.showlist = false;
            if(a.imageUrl){
                list.등록이미지 = a.imageUrl;
            }
            list.옵션2_중국어 = a.name;

            if (list.옵션1_중국어 !== undefined) {
                list.item.productSkuInfos.forEach(e => {
                    if (e.skuAttributes[0].value === list.옵션1_중국어 &&
                        e.skuAttributes[1].value === list.옵션2_중국어)
                    {
                        list.구매재고 = e.amountOnSale
                    }
                })
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                    list.매칭상태 = '매칭완료';
                }
            } else {
                list.상품매칭.옵션1_중국어 = []
                list.item.productSkuInfos.forEach(e => {
                    if (e.skuAttributes[1].value === list.옵션2_중국어) {
                        list.상품매칭.옵션1_중국어.push({
                            name: e.skuAttributes[0].value,
                            imageUrl: e.skuAttributes[0].skuImageUrl ? e.skuAttributes[0].skuImageUrl : '',
                        })
                    }
                })
            }

            // if(this.checkstock){

            // if(list.상품매칭.옵션1_중국어 && list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어)){
            //     var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
            //     var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
            //     list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
            //     list.구매sku = list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).sku_id;
            //     if(list.구매재고 == 0){
            //         alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
            //     } else {
            //         alert('구매 가능한 재고수량 : ' + list.구매재고)
            //     }
            //     list.매칭상태 = '매칭완료'
            // }
            //
            // // }
            // if(list.등록이미지 == '' && list.옵션이미지 == ''){
            //     list.등록이미지 = list.item.pic_url;
            //     list.옵션이미지 = list.item.pic_url;
            // }
            // this.showlist = true;
            console.log(list)
        },
        sendmessage(url){
            window.postMessage({greeting: "importproductinfo",url:url},"*",)
        },
        // async getProductInfo(구매링크){
        //     var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
        //     console.log(productNo)
        //     try{
        //         var res = await axios.post('/api/reqpurchase/getProductInfo',[productNo])
        //         return res.data.result[0].item
        //     }catch(e){
        //         console.log('err821' + e);
        //         return false
        //     }
        // },
        async getProductInfo(구매링크){
            var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
            try{
                var res = await axios.post('/api/aiPage/getProductInfo',[{productId:parseInt(productNo)}])
                return res
            }catch(e){
                console.log('err821' + e);
                return false
            }
        },


        async confirmreq(obj){
            if(!obj.구매링크){
                obj.showspinner = false;
                return obj
            }
            obj.구매링크init = obj.구매링크;
            obj.매칭상태 = '매칭필요'
            var item = await this.getProductInfo(obj.구매링크);
            item = item.data.result[0].result.result
            console.log(item)
            obj.item = item;

            let option1array = [];
            let option2array = [];

            if (item.productSkuInfos) {
                item.productSkuInfos.forEach((e) => {
                    if (!option1array.find(option => option.name === e.skuAttributes[0].value)) {
                        option1array.push({
                            name: e.skuAttributes[0].value,
                            imageUrl: e.skuAttributes[0].skuImageUrl ? e.skuAttributes[0].skuImageUrl : '',
                        })
                    }

                    if (e.skuAttributes[1]) {
                        if (!option2array.find(option => option.name === e.skuAttributes[1].value)) {
                            option2array.push({
                                name: e.skuAttributes[1].value,
                                imageUrl: e.skuAttributes[1].skuImageUrl ? e.skuAttributes[1].skuImageUrl : '',
                            })
                        }
                    } else {
                        option2array = []
                    }
                })
            }

            if(option1array.length === 0){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = item.productSaleInfo.amountOnSale;
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.productImage.images[0];
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.productImage.images[0];
                obj.매칭상태 = '매칭완료'
            } else {
                var imgUrl = '';
                if(option2array.length == 0){
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                    } else {
                        obj.구매재고 = item.productSkuInfos.find(f => f.skuAttributes[0].value == obj.옵션1_중국어).amountOnSale;
                        obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : option1array.find(e => e.name == obj.옵션1_중국어).imageUrl;
                        obj.매칭상태 = '매칭완료'
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = [];
                    obj.옵션2_중국어 = '';
                    imgUrl = option1array.find(e => e.name == obj.옵션1_중국어).imageUrl;
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : option1array.find(e => e.name == obj.옵션1_중국어).imageUrl;
                } else {
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                    } else {
                        obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : option1array.find(e => e.name == obj.옵션1_중국어).imageUrl;
                        if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                            obj.옵션2_중국어 = undefined;
                            obj.구매재고 = 0;
                        } else {
                            obj.구매재고 = item.productSkuInfos.find(f => f.skuAttributes[0].value == obj.옵션1_중국어 && f.skuAttributes[1].value == obj.옵션2_중국어).amountOnSale;
                            imgUrl = option1array.find(e => e.name == obj.옵션1_중국어).imageUrl ? option1array.find(e => e.name == obj.옵션1_중국어).imageUrl : (option2array.find(e => e.name == obj.옵션2_중국어).imageUrl ? option2array.find(e => e.name == obj.옵션2_중국어).imageUrl : "");
                            obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : (option1array.find(e => e.name == obj.옵션1_중국어).imageUrl ? option1array.find(e => e.name == obj.옵션1_중국어).imageUrl : (option2array.find(e => e.name == obj.옵션2_중국어).imageUrl ? option2array.find(e => e.name == obj.옵션2_중국어).imageUrl : "") );
                            obj.매칭상태 = '매칭완료'
                        }
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = option2array;
                    console.log(option1array)
                    console.log(option1array.find(e => e.name == obj.옵션1_중국어))
                    console.log(option2array)

                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                }

                if(!obj.등록이미지 && !option1array.find(e => e.imageUrl) && !option1array.find(e => e.imageUrl)){
                    obj.등록이미지 = item.productImage.images[0];
                }
            }
            obj.showspinner = false;
            return obj
        },
        async matchoption1(index,구매링크){
            this.lists[index].showspinner = true;
            this.lists[index].구매링크 = 구매링크;
            this.currentindex = index;
            // setTimeout(() => {this.sendmessage(구매링크);},1)
            var newObj = await this.confirmreq(this.lists[index]);
            // console.log(newObj)
            // this.showlist = false
            this.lists[index] = newObj;
            console.log(this.lists[this.currentindex])

            // axios.post('/api/reqpurchase/geturlinfo',{
            //     url : 구매링크
            // })
            // .then((res) => {
            //     if(res.data.요청결과 == 'success'){
            //         console.log(res.data.result)
            //         var obj = {}
            //         res.data.result.option1array.forEach(e => {
            //             obj = {
            //                 image : e.imageUrl,
            //                 optionname : e.name,
            //             }
            //             list.상품매칭.옵션1_중국어.push(obj)
            //         })
            //         res.data.result.option2array.forEach(e => {
            //             obj = {
            //                 image : e.imageUrl,
            //                 optionname : e.name,
            //             }
            //             list.상품매칭.옵션1_중국어.push(obj)
            //         })

            //     } else {
            //         alert('error777 :' + res.data.요청결과)
            //     }
            // })
            // .catch(console.log)
        },
        matchclass(a){
            if(a == '매칭완료'){
                return 'badge badge-success';
            } else {
                return 'badge badge-warning';
            }
        },
        reqclass(a){
            if(a == '등록완료'){
                return 'badge badge-success';
            } else {
                return 'badge badge-warning';
            }
        },
        excelDownFunc(){
            var arr = [];
            var myHeader = ["바코드","구매요청수량"];

            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);

            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, '구매대행요청양식');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, '구매대행요청양식.xlsx');
        },
        filterByStatus(status) {
            this.reqlists = this.reqlistsinit.filter(e => e.진행현황 === status);
        },
        async uploadfile(event) {
            var ob = {};
            // this.showlist = false;
            this.showuploadspinner = true;
            if(this.lists.length > 0 && !confirm('현재 구매요청 내역을 유지하시겠습니까?')){
                this.lists = [];
            }
            if(this.$store.state.growthdb.length == 0){
                var result = await axios.post('/api/getGrowthDB',{show:true});
                this.$store.commit('growthdbupdate', result.data.growthdb);
            }

            // var 주문번호,판매처,배송단가,할인단가,운송장번호,주문날짜,주문상태;
            setTimeout(async () => {
                const file = event.target.files[0];
                let reader = new FileReader();
                reader.onload = async (e) => {
                    // let data = reader.result;
                    let data = e.target.result;
                    let workbook = XLSX.read(data, {type: 'binary'});
                    workbook.SheetNames.forEach(async (sheetName) => {
                        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        console.log(roa)
                        // this.uploadreqfile(roa);

                        if(!roa.find(e => this.$store.state.growthdb.find(ele => ele.barcode == e.바코드))){
                            alert('상품DB를 먼저 등록해주세요')
                            this.showlist = true;
                            this.showuploadspinner = false;
                            return
                        } else {
                            for(var i=0;i<roa.length;i++){
                                var e = roa[i]
                                ob = this.$store.state.growthdb.find(ele => ele.barcode == e.바코드);
                                for(var j=0;j<ob.구매정보.length;j++){
                                    var f = ob.구매정보[j];
                                    this.uploadstatus = parseInt((i+1)/roa.length*100) + '% 업로드 중...(' + (j+1) + "/" + ob.구매정보.length + ")";
                                    var conf = await this.confirmreq(f);
                                    conf.구매요청수량 = parseInt(e.구매요청수량);
                                    this.lists.push(this.deepClone(conf))
                                }
                            }
                        }
                        // this.showlist = true;
                        this.showuploadspinner = false;
                    });
                };
                reader.readAsBinaryString(file);
            }, 10);
        },
        async uploadreqfile(req){
           this.change = true;
           if(req.length == 0){
               alert('구매요청수량이 없습니다.')
               return
           }
           console.log('req'. req)
           var 요청건수 = req.filter(e => parseInt(e.구매요청수량) > 0).length;
           var 요청수량 = req.reduce((pv,cv) => {return pv + parseInt(cv.구매요청수량)},0);
           if(confirm(요청건수 + '건(총수량:' + 요청수량 + '개)의 내역을 구매요청하시겠습니까?')){
               var arr = [];
               var nosku = [];
               for(var i=0;i<req.length;i++){
                   var e = req[i];
                   console.log(e.바코드)
                   var obj = await this.addexcellists(e);
                   if(obj.nosku){
                       nosku.push(e)
                   } else {
                       console.log(obj)
                       arr.push(this.deepClone(obj));
                   }
               }
               if(nosku.length > 0){
                   var noskulist = ''
                   for(var j=0;j<nosku.length;j++){
                       if(j == nosku.length - 1){
                           noskulist += nosku[j]
                       } else {
                           noskulist += nosku[j] + '\n'
                       }
                   }
                   alert('아래의 바코드는 SKU정보가 없어서 추가되지 않았습니다. SKU리스트 수집을 먼저 진행해 주세요. \n' + noskulist)
               } else {
                   await this.addreqlist(arr)
               }
               this.showuploadspinner = false;
               this.showlist = true;
               this.uploadstatus = '여기를 클릭해서 선택하거나, 엑셀파일을 끌어다 놓으세요.'
               this.$bvModal.hide('excelupload');
           }
       },
       async addreqlist(lists){
           console.log(lists)
           try{
               var res = await axios.post('/api/reqpurchase/addreqlist',lists)
               console.log(res)
               if(res){
                   alert(lists.length + '건이 추가되었습니다.')
                   this.getreqlists();
                   this.$bvModal.hide('reqpurchase');
                   return
               }
           }catch(e){
               console.log(e)
           }
           alert('추가실패');
       },
        async addexcellists(e){
            if(this.$store.state.growthdb && this.$store.state.growthdb.find(ele => ele.바코드 == e.바코드)){
                var ob = this.$store.state.growthdb.find(ele => ele.바코드 == e.바코드)
                if(ob.상품매칭){
                    var 상품매칭 = ob.상품매칭
                } else {
                    상품매칭 = {
                        옵션1_중국어 : [],
                        옵션2_중국어 : [],
                    }
                }
                if(ob.요청내역){
                    var 요청내역 = ob.요청내역
                } else {
                    요청내역 = {
                        default : true,
                        poarrange : false,

                        한글표시사항 : true,
                        barcode : true,
                        sticker : false,
                        sewing : false,
                        stamp : false,
                        tag : false,
                        reqcontent : '',
                        opp : false,
                        LDPE : false,
                        airpacking : false,
                        exportType : 'box',
                        destination : 'coupang',
                        masking : false,
                        auto : true,
                    }
                }
                if(ob.한글표시사항){
                    var 한글표시사항 = ob.한글표시사항;
                } else {
                    한글표시사항 = {
                        제품명 : '',
                        '수입원/판매원' : '',
                        제조원 : '',
                        제조국 : '',
                        내용량 : '',
                        '원료명및성분명(재질)' : '',
                        상품유형 : '',
                        사용시주의사항 : '',
                        사용기준 : '',
                    }
                }
                var obj = {
                    checked : false,
                    showspinner : false,
                    // SKUID : ob['SKU ID'],
                    바코드 : ob.바코드,
                    상품명 : ob.상품명,
                    상품명init : ob.상품명,
                    구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
                    구매링크init : ob.구매링크 ? ob.구매링크 : undefined,
                    옵션1_중국어 : ob.옵션1_중국어 ? ob.옵션1_중국어 : undefined,
                    옵션2_중국어 : ob.옵션2_중국어 ? ob.옵션2_중국어 : undefined,
                    옵션이미지: ob.옵션이미지 ? ob.옵션이미지 : undefined,
                    매칭상태 : ob.매칭상태 ? ob.매칭상태 : '매칭필요',
                    상품매칭 : 상품매칭,
                    구매재고 : '',
                    구매요청수량 : e.구매요청수량 ? e.구매요청수량 : '',
                    판매구성수량: ob.판매구성수량 ? ob.판매구성수량 : 1,
                    묶음구성여부 : ob.묶음구성여부 ? ob.묶음구성여부 : false,
                    묶음구성수량 : ob.묶음구성수량 ? ob.묶음구성수량 : '',
                    묶음대상바코드 : ob.묶음구성여부 ? ob.바코드 : '',
                    신고영문명 : ob.신고영문명 ? ob.신고영문명 : '',
                    신고가격 : 0,
                    HSCODE : ob.HSCODE ? ob.HSCODE : '',
                    진행현황 : '구매전',
                    재고동봉 : true,
                    등록이미지 : ob.등록이미지 ? ob.등록이미지 : '',
                    요청사항 : '요청등록',
                    reqtype : '제트배송',
                    sizeX : ob.sizeX ? ob.sizeX : '',
                    sizeY : ob.sizeY ? ob.sizeY : '',
                    sizeZ : ob.sizeZ ? ob.sizeZ : '',
                    weight : ob.weight ? ob.weight : '',
                    요청내역 : 요청내역,
                    한글표시사항 : 한글표시사항,
                    polists : [],
                    selectpo : '',
                }
                // this.reqinit(obj);
                var newObj = await this.confirmreq(obj);
                var foo = await this.confirmlist(newObj)
                if(foo){
                    newObj.요청사항 = '등록완료';
                }
                return newObj
            } else {
                return {nosku:true}
            }
        },
        modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
        downloadxlsx(lists){
            if(lists.length > 0){
                var createpo = [];
                var totalpo = [];
                //this.lists, setting값에 따라 createpo 업데이트
                var obj = {};
                var link
                var option1
                var option2
                lists.forEach(e => {
                    e.발주세부내역.forEach(ele => {
                        link = '';
                        option1 = '';
                        option2 = '';
                        this.$store.state.productdb.some(element => {
                            if(element.바코드 == ele.바코드){
                                link = element.구매링크;
                                option1 = element.옵션1_중국어;
                                option2 = element.옵션2_중국어;
                                return (element.바코드 == ele.바코드)
                            }
                        })
                        if(ele.구매필요수량>0){
                            if(link == ''){
                                alert('"' + ele.바코드 + '" 제품의 구매링크 정보가 없습니다.')
                            } else {
                                obj = {
                                    '订单号':e.발주번호,
                                    '商品名称':ele.상품명,
                                    '商品数量(件)':ele.구매필요수량,
                                    '商品规格1（如：颜色）': option1,
                                    '商品规格2（如：尺码）': option2,
                                    '收件人-姓名': this.CreatePo_수취인,
                                    '收件人-手机': this.CreatePo_연락처,
                                    '收件人-省':this.CreatePo_주소_성,
                                    '收件人-市':this.CreatePo_주소_시,
                                    '收件人-区':this.CreatePo_주소_구,
                                    '收件人-详细地址':this.CreatePo_상세주소,
                                    '收货地址（省/市/区/详细地址合并的收货地址）':'',
                                    '买家留言':ele.SKUID,
                                    '1688商品链接/1688商品id':link.slice(30,link.search('.html')),
                                    '分销or现货（默认或者填1走分销，0走现货）':0,
                                }
                                totalpo.push(obj);
                            }
                        }
                    })
                })

                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                        return -1;
                    }
                    if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                        return 1;
                    }
                    return 0;
                })

                //주문번호 변경 (발주번호 --> timestamp0001)
                var matchpo = [];
                var matchobj = {};
                var ponum = '';

                var now = Date.now()
                var n = 0;
                totalpo.forEach((e,i) => {
                    matchobj = {};
                    if(i > 0){
                        if(totalpo[i-1]['1688商品链接/1688商品id'] != totalpo[i]['1688商品链接/1688商品id']){
                            n++
                        }
                    }
                    ponum = now.toString() + (n+1).toString().padStart(4,'0')
                    matchobj = {
                        쿠플러스주문번호: ponum,
                        발주번호: e['订单号'],
                        SKUID: e['买家留言'],
                        상품명: e['商品名称'],
                        구매수량: e['商品数量(件)'],
                        상품번호: e['1688商品链接/1688商品id'],
                        option1: e['商品规格1（如：颜色）'],
                        option2: e['商品规格2（如：尺码）']
                    };
                    matchpo.push(matchobj);
                    e['订单号'] = ponum;
                })
                //axios matchpo

                axios.post('/api/addpurchase/addmatchpo',matchpo)
                .then((res) => {
                    this.$store.commit('matchpoupdate',res.data.matchpo);
                    alert(res.data.요청결과)
                })
                .catch(console.log)


                //1688po를 100개씩 잘라서 createpo array에 저장
                console.log(totalpo)
                var i
                var j
                var cnt = totalpo.length;

                for(j=0;j<cnt;j++){
                    if(totalpo.length < 100){
                        createpo.push(totalpo)
                        break
                    }
                    for(i=100;i>0;i--){
                        if(totalpo[i][['1688商品链接/1688商品id']] != totalpo[i-1][['1688商品链接/1688商品id']]){
                            console.log(totalpo.slice(0,i))
                            createpo.push(totalpo.slice(0,i))
                            totalpo = totalpo.slice(i,totalpo.length)
                            console.log(totalpo)
                            break
                        } else {
                            if(i==1){
                                createpo.push(totalpo.slice(0,100))
                                totalpo = totalpo.slice(100,totalpo.length)
                            }
                        }
                    }
                }

                //test
                // const workBook = XLSX.utils.book_new()
                // const workSheet1 = XLSX.utils.json_to_sheet(totalpo)
                // const workSheet2 = XLSX.utils.json_to_sheet(matchpo)
                // XLSX.utils.book_append_sheet(workBook, workSheet1, '1688주문서')
                // XLSX.utils.book_append_sheet(workBook, workSheet2, 'matching')
                // XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '.xlsx')


                //다운로드
                for(i=0;i<createpo.length;i++){
                    const workBook = XLSX.utils.book_new()
                    const workSheet = XLSX.utils.json_to_sheet(createpo[i])
                    XLSX.utils.book_append_sheet(workBook, workSheet, '1688주문서')
                    XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '_' + i.toString() + '.xlsx')
                }
            } else {
                alert('생성할 주문서가 없습니다.')
            }
        },
        downloadtotal(){
            //this.lists를 1688주문서로 변환해서 다운로드
            this.downloadxlsx(this.lists);
        },
        downloadselect(){
            var selectlists = [];
            this.lists.forEach(e => {
                if(e.checked == true){
                    selectlists.push(e)
                }
            })
            this.downloadxlsx(selectlists);
        },
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},


        deletelist(item){
            console.log(item)
            if (item.진행현황 === '구매전') {
                const index = this.reqlists.findIndex(i => i === item);
                var newarr = [];
                var delarr = [];
                this.reqlists.forEach((e,i) => {
                    if(i !== index){
                        newarr.push(this.deepClone(e))
                    } else {
                        delarr.push(this.deepClone(e))
                    }
                })
                this.reqlists = newarr;
                if(confirm('삭제 후에는 복구가 불가능합니다. 그래도 삭제하시겠습니까?')){
                    this.deletereqlists(delarr);
                }
            } else {
                alert('구매전인 상품만 삭제됩니다.')
            }
        },
        async deletereqlists(delarr){
            axios.post('/api/reqpurchase/deletereqpurchase',delarr)
            .then((res) => {
                if(res.data.요청결과 == '삭제완료'){
                    alert(res.data.요청결과)
                } else {
                    alert(res.data.요청결과)
                    console.log(res.data)
                }
            })
            .catch(console.log)
        },
        deletedetail(발주번호,SKUID){
            var newarr = [];
            var newobj = {};
            this.lists.forEach(e => {
                if(e.발주번호 != 발주번호){
                    newarr.push(e);
                } else {
                    newobj = this.deepClone(e);
                    newobj.발주세부내역 = [];
                    e.발주세부내역.forEach(ele => {
                        if(ele.SKUID != SKUID){
                            newobj.발주세부내역.push(ele);
                        }
                    });
                    newarr.push(newobj);
                }
            })
            this.lists = newarr;
        },
        allchecklist(allchecked){
            if(allchecked == true){
                this.lists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.lists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        allcheckdetaillist(allcheckdetail){
            if(allcheckdetail == true){
                this.detaillists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.detaillists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        getTodaywith(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        getToday(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + month + day;
        },
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime() / 1000
        },
        downloadpo(){
            if(this.downloadlists.length > 0){
                var createpo = [];
                var totalpo = this.downloadlists;


                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                        return -1;
                    }
                    if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                        return 1;
                    }
                    return 0;
                })
                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a.판매처 < b.판매처){
                        return -1;
                    }
                    if (a.판매처 > b.판매처){
                        return 1;
                    }
                    return 0;
                })


                //1688po를 100개씩 잘라서 createpo array에 저장
                console.log(totalpo)
                var i
                var j
                var cnt = totalpo.length;

                for(j=0;j<cnt;j++){
                    if(totalpo.length < 100){
                        createpo.push(totalpo)
                        break
                    }
                    for(i=100;i>0;i--){
                        if(totalpo[i][['1688商品链接/1688商品id']] != totalpo[i-1][['1688商品链接/1688商品id']]){
                            console.log(totalpo.slice(0,i))
                            createpo.push(totalpo.slice(0,i))
                            totalpo = totalpo.slice(i,totalpo.length)
                            console.log(totalpo)
                            break
                        } else {
                            if(i==1){
                                createpo.push(totalpo.slice(0,100))
                                totalpo = totalpo.slice(100,totalpo.length)
                            }
                        }
                    }
                }

                //test
                // const workBook = XLSX.utils.book_new()
                // const workSheet1 = XLSX.utils.json_to_sheet(totalpo)
                // const workSheet2 = XLSX.utils.json_to_sheet(matchpo)
                // XLSX.utils.book_append_sheet(workBook, workSheet1, '1688주문서')
                // XLSX.utils.book_append_sheet(workBook, workSheet2, 'matching')
                // XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '.xlsx')


                //다운로드
                for(i=0;i<createpo.length;i++){
                    const workBook = XLSX.utils.book_new()
                    const workSheet = XLSX.utils.json_to_sheet(createpo[i])
                    XLSX.utils.book_append_sheet(workBook, workSheet, '1688주문서')
                    XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '_' + i.toString() + '.xlsx')
                }
            } else {
                alert('생성할 주문서가 없습니다.')
            }
        },
        submit() {
            if(this.poinfocnt == 0){
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.lists = [];
                    this.submitStatus = true
                    var obj = {};
                    var sobj = {};
                    var ssobj = {};
                    var robj = {};
                    var addobj = {};
                    var addcnt = 0;
                    var rstockcnt = 0;
                    var stockcnt = 0;
                    var sstockcnt = 0;
                    var elcnt = 0;
                    var existaddcnt = 0;
                    var arrangecnt = 0;
                    var notarrangecnt = 0;
                    var pstockcnt = 0;
                    //setting값에 따라 lists 업데이트

                    //날짜로 this.totallists를 추려서 저장
                    this.totallists = this.$store.state.polist.filter(e => this.timestamp(this.startdate) <= this.timestamp(e.발주일시.slice(0,10)) && this.timestamp(this.enddate) >= this.timestamp(e.발주일시.slice(0,10)));
                    //this.totallists에서 lists 추출
                    this.totallists.forEach(e => {
                        e.발주세부내역.forEach(ele => {
                            arrangecnt = 0;
                            notarrangecnt = 0;
                            pstockcnt = 0;
                            rstockcnt = 0;
                            if (this.$store.state.stock.length != 0){
                                this.$store.state.stock.forEach(element => {
                                    if(element.바코드 == ele.바코드){
                                        if(element.배치발주번호 == e.발주번호){
                                            arrangecnt++
                                        } else {
                                            if(element.배치발주번호 == ''){
                                                if(element.Stockinfo == 'stock') {
                                                    if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                                        notarrangecnt++
                                                    }
                                                } else {
                                                    if(element.Stockinfo == 'Pstock') {
                                                        pstockcnt++
                                                    } else {
                                                        if(element.Stockinfo == 'Rstock') {
                                                            if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                                                rstockcnt++
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })
                            }
                            ele.발주수량 = parseInt(ele.발주수량);
                            ele.입고수량 = parseInt(ele.입고수량);
                            if(isNaN(ele.입고수량)){
                                ele.입고수량 = 0;
                            }
                            ele.확정수량 = parseInt(ele.확정수량);
                            ele.배치수량 = arrangecnt;
                            ele.미배치재고수량 = notarrangecnt;
                            ele.구매완료수량 = pstockcnt;
                            ele.회송예정수량 = rstockcnt;
                            ele.사입재고수량 = 0;
                            if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                this.$store.state.productdb.some(element => {
                                    if(ele.바코드 == element.바코드 && element.구매링크 == '사입'){
                                        ele.사입재고수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt;
                                        return (ele.바코드 == element.바코드 && element.구매링크 == '사입')
                                    }
                                })
                            }
                            ele.구매필요수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt - ele.사입재고수량;
                        })
                    })



                    if(this.totallists.length == 0){
                        this.showlist = false;
                        alert('생성할 리스트가 없습니다.')
                    } else {

                        this.totallists.forEach(e => {
                            addcnt = 0;
                            rstockcnt = 0;
                            stockcnt = 0;
                            sstockcnt = 0;

                            e.발주세부내역.forEach(element => {
                                existaddcnt = addcnt;
                                addcnt += parseInt(element.발주수량);
                                this.$store.state.productdb.some(el => {
                                    if(el.바코드 == element.바코드){
                                        if(el.구매링크 == '사입'){
                                            sstockcnt += element.발주수량;
                                            ssobj = {
                                                checked : false,
                                                발주번호 : e.발주번호,
                                                발주센터 : e.발주센터,
                                                입고예정일 : e.입고예정일,
                                                발주운송 : e.발주운송,
                                                SKUID : element.SKUID,
                                                바코드 : element.바코드,
                                                상품명 : element.상품명,
                                                발주수량 : element.발주수량,
                                                매입가 : element.매입가,
                                                공급가액 : element.공급가액,
                                                부가세액 : element.부가세액,
                                            };
                                            this.sstocklists.push(ssobj);
                                            addcnt -= parseInt(element.발주수량);
                                        }
                                        return (el.바코드 == element.바코드)
                                    }
                                })
                                if(existaddcnt < addcnt){
                                    this.$store.state.stock.some(ele => {
                                        if(ele.바코드 == element.바코드){
                                            if(ele.stockinfo == 'stock'){
                                                stockcnt++
                                                if(this.stocklists.length > 0){
                                                    elcnt = 0;
                                                    this.stocklists.forEach(el => {
                                                        if(el.발주번호 == e.발주번호 && el.바코드 == element.바코드){
                                                            el.수량++
                                                            elcnt++
                                                        }
                                                    })
                                                    if(elcnt == 0){
                                                        sobj = {
                                                            checked : false,
                                                            발주번호 : e.발주번호,
                                                            발주센터 : e.발주센터,
                                                            입고예정일 : e.입고예정일,
                                                            발주운송 : e.발주운송,
                                                            SKUID : element.SKUID,
                                                            바코드 : element.바코드,
                                                            상품명 : element.상품명,
                                                            발주수량 : element.발주수량, //표에서 hide
                                                            매입가 : element.매입가,
                                                            공급가액 : element.공급가액,
                                                            부가세액 : element.부가세액,

                                                            재고박스번호 : ele.재고박스번호, //표에서 show
                                                            재고파렛트번호 : ele.재고파렛트번호, //표에서 show
                                                            수량 : 1, //표에서 show
                                                        };
                                                        this.stocklists.push(sobj);
                                                    }
                                                } else {
                                                    sobj = {
                                                        checked : false,
                                                        발주번호 : e.발주번호,
                                                        발주센터 : e.발주센터,
                                                        입고예정일 : e.입고예정일,
                                                        발주운송 : e.발주운송,
                                                        SKUID : element.SKUID,
                                                        바코드 : element.바코드,
                                                        상품명 : element.상품명,
                                                        발주수량 : element.발주수량, //표에서 hide
                                                        매입가 : element.매입가,
                                                        공급가액 : element.공급가액,
                                                        부가세액 : element.부가세액,

                                                        재고박스번호 : ele.재고박스번호, //표에서 show
                                                        재고파렛트번호 : ele.재고파렛트번호, //표에서 show
                                                        수량 : 1, //표에서 show
                                                    };
                                                    this.stocklists.push(sobj);
                                                }

                                                addcnt--
                                                return (addcnt == existaddcnt)
                                            } else {
                                                if(ele.stockinfo == 'Rstock'){
                                                    rstockcnt++
                                                    if(this.rstocklists.length > 0){
                                                        elcnt = 0;
                                                        this.rstocklists.forEach(el => {
                                                            if(el.발주번호 == e.발주번호 && el.바코드 == element.바코드){
                                                                el.수량++
                                                                elcnt++
                                                            }
                                                        })
                                                        if(elcnt == 0){
                                                            robj = {
                                                                checked : false,
                                                                발주번호 : e.발주번호,
                                                                발주센터 : e.발주센터,
                                                                입고예정일 : e.입고예정일,
                                                                발주운송 : e.발주운송,
                                                                SKUID : element.SKUID,
                                                                바코드 : element.바코드,
                                                                상품명 : element.상품명,
                                                                발주수량 : element.발주수량, //표에서 hide
                                                                매입가 : element.매입가,
                                                                공급가액 : element.공급가액,
                                                                부가세액 : element.부가세액,

                                                                수량 : 1 //표에서 show
                                                            };
                                                            this.rstocklists.push(robj);
                                                        }
                                                    } else {
                                                        robj = {
                                                            checked : false,
                                                            발주번호 : e.발주번호,
                                                            발주센터 : e.발주센터,
                                                            입고예정일 : e.입고예정일,
                                                            발주운송 : e.발주운송,
                                                            SKUID : element.SKUID,
                                                            바코드 : element.바코드,
                                                            상품명 : element.상품명,
                                                            발주수량 : element.발주수량, //표에서 hide
                                                            매입가 : element.매입가,
                                                            공급가액 : element.공급가액,
                                                            부가세액 : element.부가세액,

                                                            수량 : 1 //표에서 show
                                                        };
                                                        this.rstocklists.push(robj);
                                                    }

                                                    addcnt--
                                                    return (addcnt == existaddcnt)
                                                }
                                            }
                                        }
                                    });
                                }
                                if(addcnt > 0){
                                    addobj = {
                                        checked : false,
                                        발주번호 : e.발주번호,
                                        발주센터 : e.발주센터,
                                        입고예정일 : e.입고예정일,
                                        발주운송 : e.발주운송,
                                        SKUID : element.SKUID,
                                        바코드 : element.바코드,
                                        상품명 : element.상품명,
                                        발주수량 : element.발주수량,
                                        매입가 : element.매입가,
                                        공급가액 : element.공급가액,
                                        부가세액 : element.부가세액,

                                        수량 : addcnt,
                                    }
                                    this.addlists.push(addobj);
                                }
                            })

                            obj = {
                                checked : false,
                                발주번호 : e.발주번호,
                                발주일시 : e.발주일시,
                                입고예정일 : e.입고예정일,
                                전체수량 : e.발주수량,
                                구매필요수량 : addcnt,
                                재고수량 : stockcnt,
                                사입재고수량 : sstockcnt,
                                회송재고수량 : rstockcnt,
                                발주세부내역 : e.발주세부내역
                            }
                            this.lists.push(obj);
                            this.showlist = true;
                        })

                        //리스트
                        //발주번호 - 전체수량 x개, 필요수량 x개, 회송재고 x개, 재고 x개, 사입재고 x개, 구매링크 필요수 x개 (상품DB 업데이트 필요))
                    }
                } else {
                    this.submitStatus = false;
                    alert('주문서 생성 실패')
                }
            } else {
                alert('1688주문서를 생성하려면 Products > 상품DB등록 메뉴에서 ' + this.poinfocnt + '개의 상품DB에 대한 구매정보(구매링크,중국어옵션명)를 업데이트 해주세요')
            }
        },
        savesetting() {
            var payload = {
                companyid : this.$store.state.user.companyid,
                CreatePo_수취인 : this.CreatePo_수취인,
                CreatePo_연락처 : this.CreatePo_연락처,
                CreatePo_주소_성 : this.CreatePo_주소_성,
                CreatePo_주소_시 : this.CreatePo_주소_시,
                CreatePo_주소_구 : this.CreatePo_주소_구,
                CreatePo_상세주소 : this.CreatePo_상세주소,
                CreatePo_주문번호 : this.CreatePo_주문번호,
                CreatePo_발주기준일 : this.CreatePo_발주기준일
            };

            this.$store.commit('settingsupdate', payload)
            console.log(this.$store.state.settings)
            axios.post('/api/addpurchase/addsettings',payload)
            .then((res) => {alert(res.data.요청결과)})
        }
    },
    beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
    mounted() {
		this.messageEventHandler = async(event) => {
            if(event.data.res && event.data.res === 'importpurchaseinfo'){
                console.log(event.data);
                console.log(event.data.result[0].result);
                console.log(this.lists)
                console.log(this.currentindex)
                console.log(this.lists[this.currentindex])
                if(event.data.result[0].result){
                    if(event.data.result[0].result.option1array.length == 0){
                        this.lists[this.currentindex].상품매칭.옵션1_중국어 = [];
                        this.lists[this.currentindex].옵션1_중국어 = '';
                        this.lists[this.currentindex].매칭상태 = '매칭완료';
                    } else {
                        this.lists[this.currentindex].상품매칭.옵션1_중국어 = event.data.result[0].result.option1array;
                    }
                    if(event.data.result[0].result.option2array.length == 0){
                        this.lists[this.currentindex].상품매칭.옵션2_중국어 = [];
                        this.lists[this.currentindex].옵션2_중국어 = '';
                    } else {
                        this.lists[this.currentindex].상품매칭.옵션2_중국어 = event.data.result[0].result.option2array;
                    }

                    this.lists[this.currentindex].구매링크 = this.lists[this.currentindex].구매링크init;
                    this.lists[this.currentindex].showspinner = false;


                    if(this.checkstock && Object.keys(event.data.result[0].result.info).length > 0){
                        // console.log(this.lists[this.currentindex].구매링크)
                        var obj = event.data.result[0].result.info.obj;
                        var obj1 = event.data.result[0].result.info.obj1;
                        var obj2 = event.data.result[0].result.info.obj2;
                        this.obj1 = obj1;
                        this.obj2 = obj2;
                        this.obj = obj;
                        if(obj1){
                            // console.log(this.lists[this.currentindex].구매링크)
                            if(obj1.length == 0){
                               if(Object.keys(event.data.result[0].result.info.obj).length > 0){
                                    var stockqty = event.data.result[0].result.info.obj?.globalData?.orderParamModel?.orderParam?.canBookedAmount;
                                    if(stockqty == 0){
                                        alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                                    } else {
                                        alert('구매 가능한 재고수량 : ' + stockqty)
                                    }
                                    this.lists[this.currentindex].등록이미지 = event.data.result[0].result.info.obj?.globalData?.images[0]?.fullPathImageURI;
                               }
                            } else {
                                if(this.lists[this.currentindex]?.구매링크 !== undefined && this.lists[this.currentindex]?.옵션1_중국어 !== undefined && this.lists[this.currentindex]?.옵션2_중국어 !== undefined){
                                    if(obj1.length == 1) {
                                        var name = obj1[0].value.find(e => e.name.includes(this.lists[this.currentindex].옵션1_중국어)).name;
                                        stockqty = obj2[name]?.canBookCount;
                                        if(stockqty == 0){
                                            alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                                        } else {
                                            alert('구매 가능한 재고수량 : ' + stockqty)
                                        }
                                        if(this.lists[this.currentindex].등록이미지 == ''){
                                            this.lists[this.currentindex].등록이미지 = event.data.result[0].result.info.obj?.globalData?.images[0]?.fullPathImageURI;
                                        }
                                    } else {
                                        if(!obj1[0].value.find(e => e.name.includes(this.lists[this.currentindex].옵션1_중국어))){
                                            alert('해당 링크에 "' + this.lists[this.currentindex].옵션1_중국어 + '" 옵션명이 존재하지 않습니다.')
                                            this.lists[this.currentindex].옵션1_중국어 = undefined;
                                        } else {
                                            var name1 = obj1[0].value.find(e => e.name.includes(this.lists[this.currentindex].옵션1_중국어)).name;
                                            if(!obj1[1].value.find(e => e.name.includes(this.lists[this.currentindex].옵션2_중국어))){
                                                alert('해당 링크에 "' + this.lists[this.currentindex].옵션2_중국어 + '" 옵션명이 존재하지 않습니다.')
                                                this.lists[this.currentindex].옵션2_중국어 = undefined;
                                            } else {
                                                var name2 = obj1[1].value.find(e => e.name.includes(this.lists[this.currentindex].옵션2_중국어)).name;
                                                name = name1 + "&gt;" + name2;
                                                stockqty = obj2[name]?.canBookCount;
                                                if(stockqty == 0){
                                                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                                                } else {
                                                    alert('구매 가능한 재고수량 : ' + stockqty)
                                                }
                                                if(this.lists[this.currentindex].등록이미지 == ''){
                                                    this.lists[this.currentindex].등록이미지 = event.data.result[0].result.info.obj?.globalData?.images[0]?.fullPathImageURI;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            if(Object.keys(event.data.result[0].result.info.obj).length > 0){
                                stockqty = event.data.result[0].result.info.obj?.globalData?.orderParamModel?.orderParam?.canBookedAmount;
                                if(stockqty == 0){
                                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                                } else {
                                    alert('구매 가능한 재고수량 : ' + stockqty)
                                }
                                this.lists[this.currentindex].등록이미지 = event.data.result[0].result.info.obj?.globalData?.images[0]?.fullPathImageURI;
                            }
                        }
                        this.currentindex = '';
                    }
                    if(this.lists[this.currentindex].구매링크){
                        if(this.lists[this.currentindex].옵션1_중국어){
                            if(this.lists[this.currentindex].옵션2_중국어){
                                this.lists[this.currentindex].매칭상태 = '매칭완료'
                            } else {
                                if(this.lists[this.currentindex].상품매칭.옵션2_중국어.length > 0){
                                    this.lists[this.currentindex].매칭상태 = '매칭필요'
                                } else {
                                    this.lists[this.currentindex].매칭상태 = '매칭완료'
                                }
                            }
                        } else {
                            if(this.lists[this.currentindex].상품매칭.옵션1_중국어.length > 0){
                                this.lists[this.currentindex].매칭상태 = '매칭필요'
                            } else {
                                this.lists[this.currentindex].매칭상태 = '매칭완료'
                            }
                        }
                    }
                    this.showlist = true;
                } else {
                    alert('유효하지 않은 구매링크 입니다.')
                }

            }
        }
		window.addEventListener('message', this.messageEventHandler);
        this.$nextTick(function () {
            this.getreqlists();
            this.usergrade()
        })
    }
}
</script>
<style>
.my-class .dropdown-menu {
    transform: translate3d(0px, -30px, 0px) !important;
    max-height: 100px;
    width:400px;
    z-index:10000;
    overflow-y: auto;
}
#quill-container{
    height: 50px;
}
#quill-container .ql-editor{
    min-height: unset;
}

.modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.action-button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.warning2 {
    font-size: 16px;
    color: #e83e8c;
    font-weight: bold;
}
.status {
    width: 100%;
    border: 3px solid #cccccc;
    display: flex;
    justify-content: space-between;
}
.status-area {
    width: 30%;
    padding: 20px;
}
.status-header {
    border-bottom: 1px solid #ccced2;
    text-align: center;
    margin-bottom: 12px;
}
.status-tit {
    margin-bottom: 20px;
}
.status-txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.status-txt a{
    cursor: pointer;
}
.status-txt span {
    font-weight: bold;
}

</style>
